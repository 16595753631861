import { Field } from "formik";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { FieldMessageErrorClick } from "../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../components/element/Form/FormLayout";
import { TitleV1 } from "../../components/element/Title/TitleText";
import { IK } from "../../utils/i18n/keyStore";

const CountryForm = ({ setFieldValue, values }) => {
  const { t, i18n } = useTranslation(["translation"]);
  const options = [
    { value: "01", label: t(IK.korea) },
    { value: "09", label: t(IK.australia) },
    { value: "16", label: t(IK.brazil) },
    { value: "03", label: t(IK.china) },
    { value: "18", label: t(IK.canada) },
    { value: "15", label: t(IK.egypt) },
    { value: "12", label: t(IK.france) },
    { value: "14", label: t(IK.germany) },
    { value: "20", label: t(IK.india) },
    { value: "13", label: t(IK.italy) },
    { value: "02", label: t(IK.japan) },
    { value: "10", label: t(IK.newzealand) },
    { value: "21", label: t(IK.myanmar) },
    { value: "17", label: t(IK.mexico) },
    { value: "05", label: t(IK.mongolia) },
    { value: "19", label: t(IK.russia) },
    { value: "06", label: t(IK.taiwan) },
    { value: "07", label: t(IK.thailand) },
    { value: "04", label: t(IK.usa) },
    { value: "11", label: t(IK.uk) },
    { value: "08", label: t(IK.vietnam) },
  ];

  /**선택한 옵션값 찾아서 defaultValue 지정 */
  const selectedOption = options.find((option) => option.value === values.countryCode);

  const countryHandleChange = (option) => {
    setFieldValue("countryCode", option.value);
    if (option.value === "01") {
      localStorage.setItem("langInfo", "ko");
      i18n.changeLanguage("ko");
    } else {
      localStorage.setItem("langInfo", "en");
      i18n.changeLanguage("en");
    }
  };

  return (
    <>
      <TitleV1 title={t(IK.country_code_required)} />

      <FormControlsBox required regular title={t(IK.country_code)}>
        <Field component={Select} options={options} name="countryCode" onChange={countryHandleChange} value={selectedOption} />
        <FieldMessageErrorClick name="countryCode" />
      </FormControlsBox>
    </>
  );
};

export default CountryForm;
