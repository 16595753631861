import Confirm from "../pages/Patients/Study/Sections/Confirm/Confirm";
import Extraoral from "../pages/Patients/Study/Sections/Extraoral/Extraoral";
import Intraoral from "../pages/Patients/Study/Sections/Intraoral/Intraoral";
import AP from "../pages/Patients/Study/Sections/Item/AP";
import ApAttachment from "../pages/Patients/Study/Sections/Item/ApAttachment";
import Attachment from "../pages/Patients/Study/Sections/Item/Attachment";
import BiteRamp from "../pages/Patients/Study/Sections/Item/BiteRamp";
import Crossbite from "../pages/Patients/Study/Sections/Item/Crossbite";
import Crowding from "../pages/Patients/Study/Sections/Item/Crowding";
import Deepbite from "../pages/Patients/Study/Sections/Item/Deepbite";
import DentalArchExtension from "../pages/Patients/Study/Sections/Item/DentalArchExtension";
import DentalArchTreated from "../pages/Patients/Study/Sections/Item/DentalArchTreated";
import Every10 from "../pages/Patients/Study/Sections/Item/Every10";
import Extraction from "../pages/Patients/Study/Sections/Item/Extraction";
import Instructions from "../pages/Patients/Study/Sections/Item/Instructions";
import Midline from "../pages/Patients/Study/Sections/Item/Midline";
import Overjet from "../pages/Patients/Study/Sections/Item/Overjet";
import PermanentTooth from "../pages/Patients/Study/Sections/Item/PermanentTooth";
import Spacing from "../pages/Patients/Study/Sections/Item/Spacing";
import TeethLimitedMobility from "../pages/Patients/Study/Sections/Item/TeethLimitedMobility";
import SelectAge from "../pages/Patients/Study/Sections/Option/SelectAge";
import SelectPackage from "../pages/Patients/Study/Sections/Option/SelectPackage";
import Radiation from "../pages/Patients/Study/Sections/Radiation/Radiation";
import Scan from "../pages/Patients/Study/Sections/Scan/Scan";
import Summary from "../pages/Patients/Study/Sections/Summary/Summary";
import teethLabelJson from "./TeethLabel.json";
import { IK } from "./i18n/keyStore";

const CommonSteps = {
  SelectAge: {
    label: "SelectAge",
    component: <SelectAge />,
    step: 1,
  },
  SelectPackage: {
    label: "SelectPackage",
    component: <SelectPackage />,
    step: 1,
  },
  DentalArchTreated: {
    label: "DentalArchTreated",
    component: <DentalArchTreated />,
    code: "SI01",
    validateCode: "SI01",
    step: 2,
  },
  TeethLimitedMobility: {
    label: "TeethLimitedMobility",
    component: <TeethLimitedMobility />,
    code: "SI02",
    validateCode: "SI02",
    step: 2,
  },
  Attachment: {
    label: "Attachment",
    component: <Attachment />,
    code: "SI03",
    validateCode: "SI03",
    step: 2,
  },
  DentalArchExtension: {
    label: "DentalArchExtension",
    component: <DentalArchExtension />,
    code: "SI04",
    validateCode: "SI04_01,SI04_02",
    step: 2,
  },
  AP: {
    label: "AP",
    component: <AP />,
    code: "SI05",
    validateCode: "SI05_01,SI05_02,SI05_03,SI05_05",
    step: 2,
  },
  Overjet: {
    label: "Overjet",
    component: <Overjet />,
    code: "SI06",
    validateCode: "SI06",
    step: 2,
  },
  Deepbite: {
    label: "Deepbite",
    component: <Deepbite />, //overbite
    code: "SI07",
    validateCode: "SI07_01,SI07_02,SI07_03,SI07_04",
    step: 2,
  },
  Midline: {
    label: "Midline",
    component: <Midline />,
    code: "SI08",
    validateCode: "SI08",
    step: 2,
  },
  Spacing: {
    label: "Spacing",
    component: <Spacing />,
    code: "SI09_01",
    validateCode: "SI09_01",
    step: 2,
  },
  Crowding: {
    label: "Crowding",
    component: <Crowding />,
    code: "SI09_02",
    validateCode: "SI09_02_01_01,SI09_02_02_01",
    step: 2,
  },
  BiteRamp: {
    label: "BiteRamp",
    component: <BiteRamp />,
    code: "SI10",
    validateCode: "SI10",
    step: 2,
  },
  Crossbite: {
    label: "Crossbite",
    component: <Crossbite />,
    code: "SI11",
    validateCode: "SI11",
    step: 2,
  },
  Extraction: {
    label: "Extraction",
    component: <Extraction />,
    code: "SI12",
    validateCode: "SI12",
    step: 2,
  },
  Instructions: {
    label: "Instructions",
    component: <Instructions />,
    code: "SI13",
    //validateCode: 'si13',
    step: 2,
  },
  PermanentTooth: {
    label: "PermanentTooth",
    component: <PermanentTooth />,
    code: "SI14",
    validateCode: "SI14,SI14_01,SI14_01",
    step: 2,
  },
  Every10: {
    label: "Every10",
    component: <Every10 />,
    code: "SI15",
    validateCode: "SI15",
    step: 2,
  },
  ApAttachment: {
    label: "ApAttachment",
    component: <ApAttachment />,
    code: "SI16",
    validateCode: "SI16",
    step: 2,
  },
  Extraoral: {
    label: "Extraoral",
    component: <Extraoral />,
    validateCode: "extraoral_front",
    files: true,
    step: 3,
  },
  Intraloral: {
    label: "Intraoral",
    component: <Intraoral />,
    validateCode: "occlusal_upper",
    files: true,
    step: 4,
  },
  Radiation: {
    label: "Radiation",
    component: <Radiation />,
    validateCode: "panorama",
    files: true,
    step: 5,
  },
  Scan: {
    label: "Scan",
    component: <Scan />,
    validateCode: "scan",
    files: true,
    step: 6,
  },
  Summary: {
    label: "Summary",
    component: <Summary />,
    step: 7,
  },
  Confirm: {
    label: "Confirm",
    component: <Confirm />,
    step: 8,
  },
};

export const stepTypes = {
  COMMON_PREPEND: "COMMON_PREPEND",
  COMMON_APPEND: "COMMON_APPEND",
  R: "R", //세라핀 R active
  RP: "RP", //세라핀 R passive
  S10: "S10",
  S20: "S20",
  SR: "SR", //세라핀 레귤러
  Si: "Si",
  SAP: "SAP",
  SiAP: "SiAP",
  NOT_EDIT: "NOT_EDIT",
};
export const getSteps = (packages, countryCode) => {
  if (countryCode === "01") {
    return steps[packages];
  } else {
    return steps[packages].filter((e) => e.label !== "Every10");
  }
};

export const steps = {
  [stepTypes.COMMON_PREPEND]: [CommonSteps.SelectAge, CommonSteps.SelectPackage],
  [stepTypes.R]: [
    CommonSteps.DentalArchTreated,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Attachment,
    CommonSteps.BiteRamp,
    CommonSteps.Midline,
    CommonSteps.Spacing,
    CommonSteps.Crowding,
    CommonSteps.Instructions,
  ],
  [stepTypes.RP]: [CommonSteps.DentalArchTreated, CommonSteps.BiteRamp, CommonSteps.Instructions],
  [stepTypes.S10]: [
    CommonSteps.DentalArchTreated,
    CommonSteps.Extraction,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Attachment,
    CommonSteps.DentalArchExtension,
    CommonSteps.BiteRamp,
    CommonSteps.Midline,
    CommonSteps.Spacing,
    CommonSteps.Crowding,
    CommonSteps.PermanentTooth,
    CommonSteps.Instructions,
    CommonSteps.Every10,
  ],
  [stepTypes.S20]: [
    CommonSteps.DentalArchTreated,
    CommonSteps.Extraction,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Attachment,
    CommonSteps.DentalArchExtension,
    CommonSteps.AP,
    CommonSteps.Overjet,
    CommonSteps.Deepbite,
    CommonSteps.BiteRamp,
    CommonSteps.Midline,
    CommonSteps.Spacing,
    CommonSteps.Crowding,
    CommonSteps.PermanentTooth,
    CommonSteps.Instructions,
    CommonSteps.Every10,
  ],
  [stepTypes.SR]: [
    CommonSteps.DentalArchTreated,
    CommonSteps.Extraction,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Attachment,
    CommonSteps.DentalArchExtension,
    CommonSteps.AP,
    CommonSteps.Overjet,
    CommonSteps.Deepbite,
    CommonSteps.BiteRamp,
    CommonSteps.Midline,
    CommonSteps.Spacing,
    CommonSteps.Crowding,
    CommonSteps.Crossbite,
    CommonSteps.PermanentTooth,
    CommonSteps.Instructions,
    CommonSteps.Every10,
  ],
  [stepTypes.Si]: [CommonSteps.DentalArchTreated, CommonSteps.Extraction, CommonSteps.TeethLimitedMobility, CommonSteps.Attachment, CommonSteps.DentalArchExtension, CommonSteps.Instructions],
  [stepTypes.SAP]: [
    CommonSteps.DentalArchTreated,
    CommonSteps.Extraction,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Attachment,
    CommonSteps.DentalArchExtension,
    CommonSteps.AP,
    CommonSteps.Overjet,
    CommonSteps.Deepbite,
    CommonSteps.BiteRamp,
    CommonSteps.Midline,
    CommonSteps.Spacing,
    CommonSteps.Crowding,
    CommonSteps.Crossbite,
    CommonSteps.PermanentTooth,
    CommonSteps.Instructions,
    CommonSteps.Every10,
  ],
  [stepTypes.SiAP]: [
    CommonSteps.DentalArchTreated,
    CommonSteps.Extraction,
    CommonSteps.TeethLimitedMobility,
    CommonSteps.Attachment,
    CommonSteps.ApAttachment,
    CommonSteps.DentalArchExtension,
    CommonSteps.Instructions,
  ],
  [stepTypes.COMMON_APPEND]: [CommonSteps.Extraoral, CommonSteps.Intraloral, CommonSteps.Radiation, CommonSteps.Scan, CommonSteps.Summary, CommonSteps.Confirm],
};
export const getStudyFormConfig = (packages, countryCode) => {
  if (countryCode === "01") {
    return studyFormConfig[packages];
  } else {
    delete studyFormConfig[packages].Every10;
    return studyFormConfig[packages];
  }
};
export const studyFormConfig = {
  [stepTypes.R]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: true,
      mandible: true,
      dental_arch_treated_item2_1: true,
      dental_arch_treated_item2_2: true,
      dental_arch_treated_item2_3: true,
      dental_arch_treated_item3_1: true,
      dental_arch_treated_item3_2: true,
      dental_arch_treated_item3_3: true,
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Biteramp: {
      not_used: true,
      biteramp_item2: true,
      incisor: true,
      central_incisor: true,
      lateral_incisor: true,
      canine_teeth: true,
    },
    Midline: {
      midline_item1: true,
      midline_item2: true,
      midline_item3: true,
      midline_item3_1: true,
      midline_item3_2: true,
      midline_item3_3: true,
      midline_item3_3_1: true,
      midline_item4: false,
    },
    Spacing: {
      spacing_item1: true,
      spacing_item2: true,
    },
    Crowding: {
      crowding_item1_title: true,
      crowding_item2_title: true,
      crowding_item3_title: true,
      crowding_item4_title: true,
      crowding_item5_title: true,
      crowding_item6_title: true,
      crowding_item7_title: true,
      crowding_item8_title: true,
    },
    Instructions: true,
    Extraoral: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: false,
      intraoral_front: false,
      occlusal_lower: false,
      buccal_right: false,
      overjet: false,
      buccal_left: false,
    },
    Radiation: {
      panorama: false,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.RP]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: true,
      mandible: true,
    },
    Biteramp: {
      not_used: true,
      biteramp_item2: true,
      incisor: true,
      central_incisor: true,
      lateral_incisor: true,
      canine_teeth: true,
    },
    Instructions: true,
    Extraoral: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: false,
      intraoral_front: false,
      occlusal_lower: false,
      buccal_right: false,
      overjet: false,
      buccal_left: false,
    },
    Radiation: {
      panorama: false,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.S10]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: true,
      mandible: true,
      dental_arch_treated_item2_1: true,
      dental_arch_treated_item2_2: true,
      dental_arch_treated_item2_3: true,
      dental_arch_treated_item3_1: true,
      dental_arch_treated_item3_2: true,
      dental_arch_treated_item3_3: true,
    },
    Extraction: {
      extraction_item1: true,
      extraction_item2: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    DentalArchExtension: {
      maxilla: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
      mandible: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
    },
    Biteramp: {
      not_used: true,
      biteramp_item2: true,
      incisor: true,
      central_incisor: true,
      lateral_incisor: true,
      canine_teeth: true,
    },
    Midline: {
      midline_item1: true,
      midline_item2: true,
      midline_item3: true,
      midline_item3_1: true,
      midline_item3_2: true,
      midline_item3_3: true,
      midline_item3_3_1: true,
      midline_item4: false,
    },
    Spacing: {
      spacing_item1: true,
      spacing_item2: true,
    },
    Crowding: {
      crowding_item1_title: true,
      crowding_item2_title: true,
      crowding_item3_title: true,
      crowding_item4_title: true,
      crowding_item5_title: true,
      crowding_item6_title: true,
      crowding_item7_title: true,
      crowding_item8_title: true,
    },
    PermanentTooth: {
      not_applicable: true,
      permanent_tooth_item1_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          4: true,
          5: true,
          6: true,
          11: true,
          12: true,
          13: true,
          29: true,
          28: true,
          27: true,
          22: true,
          21: true,
          20: true,
        },
      },
      permanent_tooth_item2_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          2: true,
          15: true,
          31: true,
          18: true,
        },
        permanent_tooth_item2_3: true,
      },
    },
    Instructions: true,
    Every10: {
      yes: true,
      no: true,
    },
    Extraoral: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    Radiation: {
      panorama: true,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.S20]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: true,
      mandible: true,
      dental_arch_treated_item2_1: true,
      dental_arch_treated_item2_2: true,
      dental_arch_treated_item2_3: true,
      dental_arch_treated_item3_1: true,
      dental_arch_treated_item3_2: true,
      dental_arch_treated_item3_3: true,
    },
    Extraction: {
      extraction_item1: true,
      extraction_item2: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    DentalArchExtension: {
      maxilla: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
      mandible: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
    },
    AP: {
      ap_item1_title: {
        ap_item1_1: true,
        ap_item1_2: true,
        ap_item1_3: true,
      },
      ap_item2_title: {
        maintain: true,
      },
      ap_item3_title: {
        right: true,
        left: true,
      },
      ap_item4_title: {
        right: true,
        left: true,
      },
      ap_item5_title: {
        ap_item5_1: true,
        ap_item5_2: true,
        ap_item5_3: false,
        ap_item5_4: false,
        ap_item5_5: false,
      },
    },
    Overjet: {
      overjet_item1: true,
      maintain_initial_state: true,
      overjet_item3: true,
    },
    Deepbite: {
      deepbite_item1: {
        deepbite_item1_1: true,
        deepbite_item1_2: true,
        deepbite_item1_3: true,
      },
      deepbite: {
        deepbite_item2_1: true,
        deepbite_item2_2: true,
        deepbite_item2_3: true,
      },
      deepbite_item3: {
        deepbite_item3_1: true,
        deepbite_item3_2: true,
        deepbite_item3_3: true,
        deepbite_item3_4: true,
        etc: true,
      },
      deepbite_item4: {
        deepbite_item4_1: true,
        deepbite_item4_2: true,
        deepbite_item4_3: true,
        deepbite_item4_4: true,
        etc: true,
      },
    },
    Biteramp: {
      not_used: true,
      biteramp_item2: true,
      incisor: true,
      central_incisor: true,
      lateral_incisor: true,
      canine_teeth: true,
    },
    Midline: {
      midline_item1: true,
      midline_item2: true,
      midline_item3: true,
      midline_item3_1: true,
      midline_item3_2: true,
      midline_item3_3: true,
      midline_item3_3_1: true,
      midline_item4: false,
    },
    Spacing: {
      spacing_item1: true,
      spacing_item2: true,
    },
    Crowding: {
      crowding_item1_title: true,
      crowding_item2_title: true,
      crowding_item3_title: true,
      crowding_item4_title: true,
      crowding_item5_title: true,
      crowding_item6_title: true,
      crowding_item7_title: true,
      crowding_item8_title: true,
    },
    PermanentTooth: {
      not_applicable: true,
      permanent_tooth_item1_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          4: true,
          5: true,
          6: true,
          11: true,
          12: true,
          13: true,
          29: true,
          28: true,
          27: true,
          22: true,
          21: true,
          20: true,
        },
      },
      permanent_tooth_item2_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          2: true,
          15: true,
          31: true,
          18: true,
        },
        permanent_tooth_item2_3: true,
      },
    },
    Instructions: true,
    Every10: {
      yes: true,
      no: true,
    },
    Extraoral: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    Radiation: {
      panorama: true,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.SAP]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: false,
      mandible: false,
      dental_arch_treated_item2_1: false,
      dental_arch_treated_item2_2: false,
      dental_arch_treated_item2_3: false,
      dental_arch_treated_item3_1: false,
      dental_arch_treated_item3_2: false,
      dental_arch_treated_item3_3: false,
    },
    Extraction: {
      extraction_item1: true,
      extraction_item2: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    DentalArchExtension: {
      maxilla: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
      mandible: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
    },
    AP: {
      ap_item1_title: {
        ap_item1_1: true,
        ap_item1_2: true,
        ap_item1_3: true,
      },
      ap_item2_title: {
        maintain: true,
      },
      ap_item3_title: {
        right: true,
        left: true,
      },
      ap_item4_title: {
        right: true,
        left: true,
      },
      ap_item5_title: {
        ap_item5_1: true,
        ap_item5_2: true,
        ap_item5_3: true,
        ap_item5_4: true,
        ap_item5_5: true,
      },
    },
    Overjet: {
      overjet_item1: true,
      maintain_initial_state: true,
      overjet_item3: true,
    },
    Deepbite: {
      deepbite_item1: {
        deepbite_item1_1: true,
        deepbite_item1_2: true,
        deepbite_item1_3: true,
      },
      deepbite: {
        deepbite_item2_1: true,
        deepbite_item2_2: true,
        deepbite_item2_3: true,
      },
      deepbite_item3: {
        deepbite_item3_1: true,
        deepbite_item3_2: true,
        deepbite_item3_3: true,
        deepbite_item3_4: true,
        etc: true,
      },
      deepbite_item4: {
        deepbite_item4_1: true,
        deepbite_item4_2: true,
        deepbite_item4_3: true,
        deepbite_item4_4: true,
        etc: true,
      },
    },
    Biteramp: {
      not_used: true,
      biteramp_item2: true,
      incisor: true,
      central_incisor: true,
      lateral_incisor: true,
      canine_teeth: true,
    },
    Midline: {
      midline_item1: true,
      midline_item2: true,
      midline_item3: true,
      midline_item3_1: true,
      midline_item3_2: true,
      midline_item3_3: true,
      midline_item3_3_1: true,
      midline_item4: false,
    },
    Spacing: {
      spacing_item1: true,
      spacing_item2: true,
    },
    Crowding: {
      crowding_item1_title: true,
      crowding_item2_title: true,
      crowding_item3_title: true,
      crowding_item4_title: true,
      crowding_item5_title: true,
      crowding_item6_title: true,
      crowding_item7_title: true,
      crowding_item8_title: true,
    },
    Crossbite: {
      crossbite_item1: true,
      crossbite_item2: true,
    },

    PermanentTooth: {
      not_applicable: true,
      permanent_tooth_item1_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          4: true,
          5: true,
          6: true,
          11: true,
          12: true,
          13: true,
          29: true,
          28: true,
          27: true,
          22: true,
          21: true,
          20: true,
        },
      },
      permanent_tooth_item2_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          2: true,
          15: true,
          31: true,
          18: true,
        },
        permanent_tooth_item2_3: true,
      },
    },
    Instructions: true,
    Every10: {
      yes: true,
      no: true,
    },
    Extraoral: {
      extraoral_front: true,
      front_rest: false,
      front_smile: true,
      side45: false,
      side90: true,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    Radiation: {
      panorama: true,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.Si]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: true,
      mandible: true,
      dental_arch_treated_item2_1: true,
      dental_arch_treated_item2_2: true,
      dental_arch_treated_item2_3: true,
      dental_arch_treated_item3_1: true,
      dental_arch_treated_item3_2: true,
      dental_arch_treated_item3_3: true,
    },
    Extraction: {
      extraction_item1: true,
      extraction_item2: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    DentalArchExtension: {
      maxilla: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
      mandible: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
    },
    Instructions: true,
    Extraoral: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    Radiation: {
      panorama: true,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.SiAP]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: false,
      mandible: false,
      dental_arch_treated_item2_1: false,
      dental_arch_treated_item2_2: false,
      dental_arch_treated_item2_3: false,
      dental_arch_treated_item3_1: false,
      dental_arch_treated_item3_2: false,
      dental_arch_treated_item3_3: false,
    },
    Extraction: {
      extraction_item1: true,
      extraction_item2: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    ApAttachment: true,
    DentalArchExtension: {
      maxilla: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
      mandible: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
    },
    Instructions: true,
    Extraoral: {
      extraoral_front: false,
      front_rest: false,
      front_smile: false,
      side45: false,
      side90: false,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    Radiation: {
      panorama: true,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
  [stepTypes.SR]: {
    DentalArchTreated: {
      bimaxilla: true,
      maxilla: false,
      mandible: false,
      dental_arch_treated_item2_1: false,
      dental_arch_treated_item2_2: false,
      dental_arch_treated_item2_3: false,
      dental_arch_treated_item3_1: false,
      dental_arch_treated_item3_2: false,
      dental_arch_treated_item3_3: false,
    },
    Extraction: {
      extraction_item1: true,
      extraction_item2: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    TeethLimitedMobility: {
      teeth_limited_mobility_item1: true,
      teeth_limited_mobility_item2: true,
      teeth_limited_mobility_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    Attachment: {
      attachment_item1: true,
      attachment_item2: true,
      attachment_item2_teeth: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
        16: true,
        17: true,
        18: true,
        19: true,
        20: true,
        21: true,
        22: true,
        23: true,
        24: true,
        25: true,
        26: true,
        27: true,
        28: true,
        29: true,
        30: true,
        31: true,
        32: true,
      },
    },
    DentalArchExtension: {
      maxilla: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
      mandible: {
        dental_arch_extension: true,
        riority_implementation: true,
        implementation_necessary: true,
        not_necessary: true,
      },
    },
    AP: {
      ap_item1_title: {
        ap_item1_1: true,
        ap_item1_2: true,
        ap_item1_3: true,
      },
      ap_item2_title: {
        maintain: true,
      },
      ap_item3_title: {
        right: true,
        left: true,
      },
      ap_item4_title: {
        right: true,
        left: true,
      },
      ap_item5_title: {
        ap_item5_1: true,
        ap_item5_2: true,
        ap_item5_3: true,
        ap_item5_4: true,
        ap_item5_5: true,
      },
    },
    Overjet: {
      overjet_item1: true,
      maintain_initial_state: true,
      overjet_item3: true,
    },
    Deepbite: {
      deepbite_item1: {
        deepbite_item1_1: true,
        deepbite_item1_2: true,
        deepbite_item1_3: true,
      },
      deepbite: {
        deepbite_item2_1: true,
        deepbite_item2_2: true,
        deepbite_item2_3: true,
      },
      deepbite_item3: {
        deepbite_item3_1: true,
        deepbite_item3_2: true,
        deepbite_item3_3: true,
        deepbite_item3_4: true,
        etc: true,
      },
      deepbite_item4: {
        deepbite_item4_1: true,
        deepbite_item4_2: true,
        deepbite_item4_3: true,
        deepbite_item4_4: true,
        etc: true,
      },
    },
    Biteramp: {
      not_used: true,
      biteramp_item2: true,
      incisor: true,
      central_incisor: true,
      lateral_incisor: true,
      canine_teeth: true,
    },
    Midline: {
      midline_item1: true,
      midline_item2: true,
      midline_item3: true,
      midline_item3_1: true,
      midline_item3_2: true,
      midline_item3_3: true,
      midline_item3_3_1: true,
      midline_item4: false,
    },
    Spacing: {
      spacing_item1: true,
      spacing_item2: true,
    },
    Crowding: {
      crowding_item1_title: true,
      crowding_item2_title: true,
      crowding_item3_title: true,
      crowding_item4_title: true,
      crowding_item5_title: true,
      crowding_item6_title: true,
      crowding_item7_title: true,
      crowding_item8_title: true,
    },
    Crossbite: {
      crossbite_item1: true,
      crossbite_item2: true,
    },
    PermanentTooth: {
      not_applicable: true,
      permanent_tooth_item1_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          4: true,
          5: true,
          6: true,
          11: true,
          12: true,
          13: true,
          29: true,
          28: true,
          27: true,
          22: true,
          21: true,
          20: true,
        },
      },
      permanent_tooth_item2_title: {
        not_used: true,
        permanent_tooth_item1_2: {
          2: true,
          15: true,
          31: true,
          18: true,
        },
        permanent_tooth_item2_3: true,
      },
    },
    Instructions: true,
    Every10: {
      yes: true,
      no: true,
    },
    Extraoral: {
      extraoral_front: true,
      front_rest: false,
      front_smile: true,
      side45: false,
      side90: true,
      side90_smile: false,
    },
    Intraoral: {
      occlusal_upper: true,
      intraoral_front: false,
      occlusal_lower: true,
      buccal_right: true,
      overjet: true,
      buccal_left: true,
    },
    Radiation: {
      panorama: true,
      cephalo: false,
    },
    scan: {
      scan: true,
      send_model: true,
    },
  },
};

export function getSummaryLabel(studyInfo, key, t) {
  const valueLabel = {
    SI01: {
      1: t(IK.bimaxillay),
      2: t(IK.maxilla),
      3: t(IK.mandible),
    },
    SI01_2_01: {
      1: t(IK.dental_arch_treated_item2_1),
      2: t(IK.dental_arch_treated_item2_2),
      3: t(IK.dental_arch_treated_item2_3),
    },
    SI01_3_01: {
      1: t(IK.dental_arch_treated_item3_1),
      2: t(IK.dental_arch_treated_item3_2),
      3: t(IK.dental_arch_treated_item3_3),
    },
    SI02: {
      1: t(IK.teeth_limited_mobility_item1),
      2: t(IK.teeth_limited_mobility_item2),
    },
    SI03: {
      1: t(IK.attachment_item1),
      2: t(IK.attachment_item2),
    },
    SI04_01: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI04_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI05_01: {
      1: t(IK.ap_item1_1),
      2: t(IK.ap_item1_2),
      3: t(IK.ap_item1_3),
    },
    SI05_02: {
      1: `${t(IK.ap_right)} : ${t(IK.ap_item3_title)}`,
      2: `${t(IK.ap_right)} : ${t(IK.ap_item4_title)}`,
    },
    SI05_03: {
      1: `${t(IK.ap_left)} : ${t(IK.ap_item3_title)}`,
      2: `${t(IK.ap_left)} : ${t(IK.ap_item4_title)}`,
    },
    SI05_04: {
      1: t(IK.ap_item5_1),
      2: t(IK.ap_item5_2),
      3: t(IK.ap_item5_3),
      4: t(IK.ap_item5_4),
      5: t(IK.ap_item5_5),
    },
    SI06: {
      1: t(IK.overjet_item1),
      2: t(IK.deepbite_item2_2),
      3: t(IK.overjet_item3),
    },
    SI07_01: {
      1: t(IK.deepbite_item1_1),
      2: t(IK.deepbite_item1_2),
      3: t(IK.deepbite_item1_3),
    },
    SI07_02: {
      1: t(IK.deepbite_item2_1),
      2: t(IK.deepbite_item2_2),
      3: t(IK.deepbite_item2_3),
    },
    SI07_03: {
      1: t(IK.deepbite_item3_1),
      2: t(IK.deepbite_item3_2),
      3: t(IK.deepbite_item3_3),
      4: t(IK.deepbite_item3_4),
      5: t(IK.other),
      6: "",
    },
    SI07_04: {
      1: t(IK.deepbite_item4_1),
      2: t(IK.deepbite_item4_2),
      3: t(IK.deepbite_item4_3),
      4: t(IK.deepbite_item4_4),
      5: t(IK.other),
      6: "",
    },
    SI08: {
      1: t(IK.midline_item1),
      2: t(IK.midline_item2),
      3: t(IK.midline_item3),
      4: t(IK.midline_item4),
    },
    SI08_3_01: {
      1: t(IK.midline_item1),
      2: t(IK.midline_item2),
      3: t(IK.midline_item3),
      4: t(IK.midline_item4),
    },
    SI08_3_02: {
      1: t(IK.midline_item3_1),
      2: t(IK.midline_item3_2),
      3: t(IK.midline_item3_3),
    },
    SI09_01: {
      1: t(IK.spacing_item1),
      2: t(IK.spacing_item2),
    },
    SI09_02_01_01: {
      1: t(IK.increase_incline),
      2: t(IK.maintain_incline),
      3: t(IK.decrease_incline),
    },
    SI09_02_01_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_01_03: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_01_04: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_01: {
      1: t(IK.increase_incline),
      2: t(IK.maintain_incline),
      3: t(IK.decrease_incline),
    },
    SI09_02_02_02: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_03: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI09_02_02_04: {
      1: t(IK.riority_implementation),
      2: t(IK.implementation_necessary),
      3: t(IK.not_necessary),
    },
    SI10: {
      1: t(IK.grant_if_necessary),
      2: t(IK.biteramp_item2),
      3: t(IK.not_used),
    },
    SI10_2_01: {
      1: t(IK.incisor),
      2: t(IK.canine_teeth),
    },
    SI10_2_01_1_01: {
      1: t(IK.central_incisor),
      2: t(IK.lateral_incisor),
    },
    SI11: {
      1: t(IK.crossbite_item1),
      2: t(IK.crossbite_item2),
    },
    SI12: {
      1: t(IK.extraction_item1),
      2: t(IK.have_teeth_to_be_extracted),
    },
    SI14: {
      1: t(IK.not_applicable),
    },
    SI14_01: {
      1: t(IK.not_used),
      2: t(IK.prmanent_tooth_item1_2),
    },
    SI14_02: {
      1: t(IK.not_used),
      2: t(IK.prmanent_tooth_item1_2),
    },
    SI15: {
      1: t(IK.yes),
      2: t(IK.no),
    },
    SI16: {
      1: t(IK.ap_attachment_text_1),
      2: t(IK.ap_attachment_text_2),
      3: t(IK.ap_attachment_text_3),
    },
    SIPlasterModel: {
      "Direct shipping": t(IK.address_delivery_check_title2),
      "Delivery pickup": t(IK.address_delivery_check_title1),
    },
  };

  const value = studyInfo[key] || [];
  if (value.includes(",")) {
    // 쉼표가 포함된 경우 여러 개의 레이블을 가져와 합쳐서 반환
    const splitArr = value.split(",");
    return splitArr.map((item) => valueLabel[key][item]).join(", ");
  }
  return valueLabel[key][value] || "";
}

export function getTeethLabel(data) {
  let splitArr = data ? data.split(",") : "";
  const result = [];
  for (let i = 0; i < splitArr.length; i++) {
    result.push(teethLabelJson[splitArr[i]]);
  }

  //오름차순 정렬
  return result.sort((a, b) => a - b).join(", ");
}

/**치아 키값 찾아내기 */
export function getTeethKeyFromLabel(label) {
  const keysOfTeeth = Object.keys(teethLabelJson);
  return keysOfTeeth.find((key) => teethLabelJson[key] === label);
}

/**세라핀 옵션명 통일 */
export const seraphinOption = {
  R: "R-active",
  RP: "R-passive",
  S10: "10",
  S20: "20",
  SR: "Regular",
  SAP: "Regular AP",
  Si: "i",
  SiAP: "iAP",
};

/**악궁 표기 */
export const getArchLabel = (key, t) => {
  const ArchList = {
    1: t(IK.bimaxillay),
    2: t(IK.maxilla),
    3: t(IK.mandible),
  };

  return ArchList[key];
};

/**삭제용 SI 아이템 리스트 */
export const studySIItemList = {
  DentalArchTreated: "SI01",
  TeethLimitedMobility: "SI02",
  Attachment: "SI03",
  DentalArchExtension: "SI04",
  AP: "SI05",
  Overjet: "SI06",
  Deepbite: "SI07",
  BiteRamp: "SI10",
  Midline: "SI08",
  Spacing: "SI09_01",
  Crowding: "SI09_02",
  Crossbite: "SI11",
  Extraction: "SI12",
  Instructions: "SI13",
  PermanentTooth: "SI14",
  Every10: "SI15",
};

/**추가 처방전 석고모델 보내기 상세 표기 */
/**
 * plasterModelSend 함수는 주어진 텍스트와 키에 따라 모델 텍스트를 반환합니다.
 * @param {function} t - 다국어 번역 함수
 * @param {string} key - 석고모델 하위 옵션 키
 * @param {string} scanEmpty - 스캔이 비어있는지 여부를 나타내는 값(추가 처방전만 사용, AI40 === "X")
 * @returns {string} - 주어진 키에 해당하는 모델 텍스트
 */
export const plasterModelSend = (t, key, scanEmpty) => {
  if (scanEmpty === "X") return t(IK.empty);

  const modelText = {
    "Direct shipping": t(IK.address_delivery_check_title2),
    "Delivery pickup": t(IK.address_delivery_check_title1),
  };

  return `${t(IK.send_model)}${modelText[key] ? `: ${modelText[key]}` : ""}`;
};
