import { styled } from "styled-components";

export const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
  padding: 25px;
  background-color: ${(props) => props.theme.colors.gray300};
  border-radius: 10px;
  h2 {
    margin-top: 20px;
    font-size: 24px;
  }
  p {
    margin-top: 15px;
    font-size: 18px;
  }
`;
