import dayjs from "dayjs";

/**검색조건 param 설정 */
export const changeParam = (searchCondition, thisPage) => {
  const { page, sort, startDate, endDate, tabFilter, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);

  if (startDate && endDate) {
    q.push(`createdDate:${dayjs(startDate).format("YYYY-MM-DD")}_${dayjs(endDate).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: thisPage === "gallery" ? 8 : 7,
    sort: sort,
  };
};

/**게시판 검색조건 param */
export const changeBoardParam = (searchCondition) => {
  const { page, sort, searchSelect, searchText, endEvent, searchStart, searchEnd, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);

  if (!!searchText) {
    q.push(`${searchSelect}:${searchText}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
    sort: sort,
  };
};

/**이벤트 게시판 검색조건 param */
export const changeEventParam = (searchCondition) => {
  const { page, sort, searchStart, searchEnd, searchSelect, searchText, ...rest } = searchCondition;

  const q = Object.entries(rest)
    .filter(([, value]) => value !== "")
    .map(([key, value]) => `${key}:${value}`);

  if (!!searchText) {
    q.push(`${searchSelect}:${searchText}`);
  }

  if (searchStart && searchEnd) {
    q.push(`startDate:${dayjs(searchStart).format("YYYY-MM-DD")}_${dayjs(searchEnd).format("YYYY-MM-DD")}`);
  }

  return {
    q: q.join(","),
    page: page - 1,
    size: 10,
  };
};

/**영어 대소문자, 한글만 입력 처리 */
export const replaceInput = (input) => {
  return input.replace(/[^a-zA-Z가-힣]/g, "");
};

/**param을 객체로 처리 */
export function urlParamToObject() {
  return Object.fromEntries(new URLSearchParams(window.location.search));
}
