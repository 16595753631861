import Notiflix from "notiflix";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { deleteRefreshToken } from "../apis/joinApi";
import { postAdminOtt } from "../apis/ottApi";
import { userInfo } from "../atoms/userAtom";
import { setAllToken } from "../utils/handleToken";
import { IK } from "../utils/i18n/keyStore";

const AdminLogin = () => {
  const { t } = useTranslation(["translation"]);
  const ott = new URL(window.location.href).searchParams.get("ott");
  const setUserInfo = useSetRecoilState(userInfo);
  const navigate = useNavigate();

  useEffect(() => {
    postAdminOtt(ott)
      .then((response) => {
        if (response.status === 200) {
          const Logindata = response.data.data;
          setAllToken(Logindata.accessToken);
          setUserInfo(Logindata.roleInfo?.DOCTOR);
          navigate("/patient/list");
        }
      })
      .catch((err) => {
        Notiflix.Report.info("", t(IK.again_message), t(IK.confirm), () => {
          localStorage.removeItem("accessToken");
          localStorage.removeItem("recoil-persist");
          deleteRefreshToken();
          navigate("/");
        });
      });
  }, [ott, navigate, setUserInfo, t]);

  return <div>{t(IK.admin_login_auth)}</div>;
};

export default AdminLogin;
