import SelectArrow from "../assets/images/common/select.png";

const { createGlobalStyle } = require("styled-components");

const GlobalStyle = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html,
    body {
        width: 100%;
        overflow-wrap: break-word;
        word-break: break-word;
        -webkit-text-size-adjust: 100%;
    }

    body,
    input,
    select,
    textarea,
    button {
        line-height: 1.5;
        letter-spacing: -0.5px;
        font-size: 14px;
        font-family: 'Montserrat', 'Pretendard';
    }

    img {
        border-style: none;
        vertical-align: middle;
    }

    a {
        color: ${(props) => props.theme.colors.black};
        text-decoration: none;
        transition: all 0.3s;
        &:active, &:hover {
            text-decoration: none;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }

    ol,
    ul,
    li {
        list-style: none;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    strong,
    b,
    th {
        font-weight: 600;
    }

    input,
    select,
    button:focus,
    button:active,
    textarea {
        outline: none;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        background: ${(props) => props.theme.colors.white} url(${SelectArrow}) no-repeat center right 12px;
        ::-ms-expand {
            display: none;
        }
    }

    input::-ms-clear {
        display: none;
    }

    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input::-moz-placeholder,
    textarea:focus::-moz-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input:-ms-input-placeholder,
    textarea:focus:-ms-input-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input:-moz-placeholder,
    textarea:focus:-moz-placeholder {
        color: ${(props) => props.theme.colors.gray500};
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    textarea {
        overflow: auto;
    }

    button {
        -webkit-appearance: button;
        border-style: none;
        cursor: pointer;
        background-color: transparent;
        transition: all 0.3s;
    }

    picture,
    iframe,
    video,
    canvas,
    svg {
        display: block;
        max-width: 100%;
    }

    iframe {
        border: 0;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        &-thumb {
            height: 10%;
            background-color: ${(props) => props.theme.colors.gray400};
            border-radius: 5px;
        }
        &-track {
            background-color: ${(props) => props.theme.colors.gray300};
        }
        &-corner {
            background-color: ${(props) => props.theme.colors.gray300};
        }
    }

    .hidden,
    caption,
    legend {
        position: absolute;
        clip: rect(0 0 0 0);
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        border: 0;
    }

    @page {
        size:A4; /*A4*/
        margin:5mm
    }

    #wrapper {
        min-width: 1900px;
    }

    //align
    .align_left {
        text-align: left !important;
    }

    /**
     * ST : ========== common ==========
     */

    #container .hide {
        display: none;
    }

    .noti_box {
        display: flex;
        justify-content: center;
        gap: 5px;
        padding: 15px;
        margin-top: 30px;
        background-color: ${(props) => props.theme.colors.gray300};
    }

    /**
     * EN : ========== common ==========
     */


    /**
     * ST : ========== layout ==========
     */

    .card_flex {
        display: flex;
        gap: 20px;
        &.v2 {
            align-items: flex-start;
        }
        &.v3 {
            gap: 50px;
        }
    }
    
    .col_wd_pre {
        flex: 1;
    }

    /**
     * EN : ========== layout ==========
     */


    /**
     * ST : ========== checkbox, radio ==========
     */
    
    .chk_area {
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        gap: 13px 20px;
        &.v2 {
            gap: 10px;
        }
    }

    .input_checkbox {
        position: absolute;
        top: 2px;
        width: 20px;
        height: 20px;
        appearance: none;
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.gray200};
        border-radius: 3px;
        vertical-align: middle;
        cursor: pointer;
    }

    .input_checkbox:before {
        content: "\\f00c";
        font-family: FontAwesome;
        font-size: 10px;
        color: transparent;
        position: absolute;
        left: 0;
        right: 0;
        top: 2px;
        text-align: center;
    }

    .input_checkbox:checked {
        background-color: ${(props) => props.theme.colors.blue400};
        border-color: ${(props) => props.theme.colors.blue400};
    }

    .input_checkbox:checked:before {
        color: ${(props) => props.theme.colors.white};
    }

    .input_radio {
        position: absolute;
        top: 2px;
        width: 20px;
        height: 20px;
        appearance: none;
        background-color: ${(props) => props.theme.colors.white};
        border: 1px solid ${(props) => props.theme.colors.gray200};
        border-radius: 50%;
        vertical-align: middle;
        cursor: pointer;
    }

    .input_radio:checked {
        border: 4px solid ${(props) => props.theme.colors.blue400};
    }

    .label_checkbox,
    .label_radio {
        position: relative;
        display: inline-block;
        line-height: 1.5;
        cursor: pointer;
        font-size: 15px;
    }

    .label_checkbox span,
    .label_radio span {
        display: inline-block;
        padding-left: 29px;
        text-align: left;
    }

    .input_checkbox:checked + span,
    .input_radio:checked + span {
        font-weight: 500;
        color: ${(props) => props.theme.colors.blue400};
    }

    .label_checkbox p,
    .label_radio p {
        position:relative;
        margin-top:3px;
        padding-left: 40px;
        font-size:14px;
        color:${(props) => props.theme.colors.gray600};
        &:before {
            content:'';
            position:absolute;
            top:8px;
            left:29px;
            width:4px;
            height:4px;
            border-radius:50%;
            background-color:${(props) => props.theme.colors.blue400};
        }
    }

    .input_checkbox:disabled,
    .input_radio:disabled {
        background-color: ${(props) => props.theme.colors.gray200};
        border: 1px solid ${(props) => props.theme.colors.gray200};
    }

    /**
     * EN : ========== checkbox, radio ==========
     */
    
    /**
     * ST : ========== form ==========
     */

    .frm_wrap {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    .frm_group {
        display: flex;
        align-items: baseline;
    }

    .frm_title * {
        font-size: 15px;
        font-weight: 600;
    }

    .frm_title {
        padding-right: 15px;
        &.v1 {
            flex: 0 0 155px;
        }
        &.v2 {
            flex: 0 0 200px;
        }
        &.v3 {
            flex: 0 0 125px;
        }
        &.v4 {
            flex: 0 0 260px;
        }
    }

    .frm_area {
        flex: 1;
        font-size: 15px;
        .css-13cymwt-control {
            min-height: 50px;
            border-color: ${(props) => props.theme.colors.gray200};
            &:hover {
            border-color: ${(props) => props.theme.colors.gray200};
            }
        }
        .css-1u9des2-indicatorSeparator {
            background-color: ${(props) => props.theme.colors.gray200};
        }
    }

    .required_title {
        position: relative;
        &:after {
            content: "*";
            margin-left: 3px;
            color: ${(props) => props.theme.colors.red600};
        }
    }

    .frm_control {
        width: 370px;
        max-width: 100%;
        height: 50px;
        padding: 10px;
        border: 0;
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.colors.gray200};
        background-color: ${(props) => props.theme.colors.white};
        font-size: 15px;
        &.v1 {
            flex: 1;
            width: 100%;
        }
        &:disabled {
            background-color: ${(props) => props.theme.colors.gray300};
        }
        &:focus-visible {
            outline: 1px solid ${(props) => props.theme.colors.blue400};
            outline-offset: -1px;
        }
    }

    input.frm_control::file-selector-button {
        display: none;
    }

    input[type="file"].frm_control {
        padding: 13px 10px;
    }

    select.frm_control {
        cursor: pointer;
    }

    textarea.frm_control {
        display: block;
        min-height: 100px;
        resize: none;
        &.v2 {
            width: 100%;
            min-height: 200px;
        }
    }

    .frm_line_field {
        display: flex;
        align-items: center;
        font-size: 15px;
        input,
        select {
            width: 30px;
            text-align:center;
            background: transparent;
            border: 0;
            border-bottom: 1px solid ${(props) => props.theme.colors.gray400};
            font-size: 15px;
            &:disabled {
                background-color: ${(props) => props.theme.colors.gray200};
            }
        }
        select {
            width: 90px;
            background: transparent url(${SelectArrow}) no-repeat center right;
            cursor: pointer;
        }
    }

    .frm_flex {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 15px;
        &.v2 {
            align-items: baseline;
        }
    }

    .frm_column {
        display: flex;
        flex-direction: column;
        gap: 10px;
        &.v2 {
            gap: 25px;
        }
    }

    .frm_radio_indent {
        margin-left: 28px;
    }

    /**
     * EN : ========== form ==========
     */
`;

export default GlobalStyle;
