import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { downloadFile } from "../../apis/boardApi";
import { getSeraviewFileId } from "../../apis/seraviewApt";
import { IK } from "../../utils/i18n/keyStore";
import Modal from "../Modal/Modal";
import * as S3 from "../element/Button/style/ButtonLayout.style";
import * as S2 from "../element/Table/style/table.style";
import { TitleV3 } from "../element/Title/TitleText";
import * as S from "./style/DownloadModal.style";

export const DownloadModal = ({ downloadModal, handleCloseDownloadModal }) => {
  const { t } = useTranslation(["translation"]);

  /**세라뷰 다운로드 */
  const downloadSeraviewFile = async () => {
    try {
      // 세라뷰 파일 정보 조회
      const { data } = await getSeraviewFileId();
      // 파일 다운로드 함수 호출
      await downloadFile(data.fileId, data.orgFileName, t);
      Notiflix.Notify.success(t(IK.download_seraview_download));
    } catch (error) {
      // 에러 발생 시 에러 메시지 출력
      console.error("파일 다운로드 중 오류 발생:", error);
    }
  };

  return (
    <Modal open={downloadModal} close={handleCloseDownloadModal} header={t(IK.download_seraview)}>
      <S.DownloadBox>
        <S.DownloadBoxIcon>
          <i className="ri-download-cloud-2-line"></i>
        </S.DownloadBoxIcon>
        <h3>{t(IK.download_seraview)}</h3>
        <p>{t(IK.download_seraview_txt_1)}</p>
        <S3.ButtonCtBox>
          <S3.StyledButton as="button" $primary onClick={downloadSeraviewFile}>
            {t(IK.download)}
          </S3.StyledButton>
        </S3.ButtonCtBox>
      </S.DownloadBox>

      <TitleV3 title={t(IK.download_seraview_txt_2)} />
      <S2.TableType1>
        <colgroup>
          <col width="50%"></col>
          <col width="50%"></col>
        </colgroup>
        <thead>
          <tr>
            <th>Item</th>
            <th>Specifications</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Operating System</td>
            <td>Windows 7 32/64 bit {t(IK.more)}</td>
          </tr>
          <tr>
            <td>CPU</td>
            <td>Intel i5 CPU 2.5 GHz {t(IK.more)}</td>
          </tr>
          <tr>
            <td>RAM</td>
            <td>8GB {t(IK.more)}</td>
          </tr>
          <tr>
            <td>Resolution</td>
            <td>1920 x 1080 {t(IK.more)}</td>
          </tr>
        </tbody>
      </S2.TableType1>
    </Modal>
  );
};
