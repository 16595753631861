import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetBannerList } from "../../apis/boardApi";
import { deleteRefreshToken } from "../../apis/joinApi";
import EventBanner from "../../pages/Support/Event/EventBanner";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Sidebar.style";
import * as S2 from "../../styles/Common";

const SidebarContent = ({ user, handleOpenDownloadModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);

  /**이벤트 배너 리스트 조회 */
  const { data: bannerData } = useGetBannerList();
  const bannerList = bannerData?.data;

  /**로그아웃 */
  function logout() {
    deleteRefreshToken();
    localStorage.removeItem("accessToken");
    localStorage.removeItem("recoil-persist");
    navigate("/");
  }

  return (
    <S.SidebarCont>
      {bannerList?.length > 0 && <EventBanner bannerList={bannerList} />}
      <S.SidebarMenuList>
        {user.countryCode === "01" && (
          <li>
            <a
              href="http://www.tnsmile.kr/bbs/board.php?bo_table=seminar"
              target="_blank"
              title="새창열림"
              rel="noreferrer noopener"
            >
              <S.MenuIco>
                <i className="ri-team-fill"></i>
              </S.MenuIco>
              <S.MenuTxt>{t(IK.seminar_registration)}</S.MenuTxt>
            </a>
          </li>
        )}
        <li>
          <a
            href="https://www.youtube.com/@user-zj2zt4ys6d"
            target="_blank"
            title="새창열림"
            rel="noreferrer noopener"
          >
            <S.MenuIco>
              <i className="ri-youtube-fill"></i>
            </S.MenuIco>
            <S.MenuTxt>{t(IK.youtube_serafin)}</S.MenuTxt>
          </a>
        </li>
        <li>
          <button type="button" onClick={handleOpenDownloadModal}>
            <S.MenuIco>
              <S2.TextDiv
                as="i"
                $textColorBlue400
                className="ri-folder-download-fill"
              ></S2.TextDiv>
            </S.MenuIco>
            <S.MenuTxt>
              <S2.TextDiv $textColorBlue400 $textBold600 $textUnderline>
                {t(IK.download_seraview)}
              </S2.TextDiv>
            </S.MenuTxt>
          </button>
        </li>
        <li>
          <button type="button" onClick={logout}>
            <S.MenuIco>
              <i className="ri-logout-box-r-fill"></i>
            </S.MenuIco>
            <S.MenuTxt>{t(IK.logout)}</S.MenuTxt>
          </button>
        </li>
      </S.SidebarMenuList>
    </S.SidebarCont>
  );
};

export default SidebarContent;
