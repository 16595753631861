import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from "react-router-dom";
import SetupInterceptors from "../apis/SetupInterceptors";
import { checkToken, deleteRefreshToken } from "../apis/joinApi";

export const IsToken = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["translation"]);
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    SetupInterceptors(navigate, t);
    if (token) {
      checkToken()
        .then((res) => navigate("/patient/list"))
        .catch((res) => {
          //오류시 로컬스토리지 삭제
          localStorage.removeItem("accessToken");
          localStorage.removeItem("recoil-persist");
          deleteRefreshToken();
          navigate("/");
        });
    }
  }, [token, navigate, t]);

  if (token) return null;

  return <Outlet />;
};
