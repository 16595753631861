import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../../../Hooks/useSearch";
import { useGetEventList } from "../../../apis/boardApi";
import * as S2 from "../../../components/Layout/CardLayout/style/CardLayout.style";
import CommonImage from "../../../components/common/CommonImage";
import { BoardSkeleton } from "../../../components/element/BoardList/BoardSkeleton";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import BoardSearchBar from "../../../components/element/BoardSearch/BoardSearchBar";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";
import { changeEventParam, urlParamToObject } from "../../../utils/searchUtils";
import EventTabType from "./EventTabType";
import { CardTail } from "../../../components/Layout/CardLayout/CardLayout";

function EventList() {
  const [searchCondition, setSearchCondition] = useState(
    window.location.search ? urlParamToObject() : { page: 1, endEvent: "N" }
  );
  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  const { data, isLoading } = useGetEventList(
    changeEventParam(searchCondition)
  );
  const boards = data?.data;

  /**페이지 설정 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  return (
    <S2.CardRow>
      <BoardSearchBar
        searchCondition={searchCondition}
        setSearchCondition={setSearchCondition}
        isDate
      />

      <S2.CardCol>
        <S2.CardInner>
          <EventTabType
            setSearchCondition={setSearchCondition}
            searchCondition={searchCondition}
          />
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              {boards.content.length > 0 ? (
                <S.BoardCardList>
                  {boards.content.map((item) => (
                    <li key={item.eventBoardId}>
                      <Link
                        to={`/support/event-list/${item.eventBoardId}`}
                        state={{ herf: window.location.search }}
                      >
                        <dl>
                          <dt>
                            <CommonImage filePath={item?.thumb} />
                          </dt>
                          <dd>
                            <h3>{item?.title}</h3>
                            <span>
                              {dayjs(item.startDate).format("YYYY.MM.DD")} -{" "}
                              {dayjs(item.endDate).format("YYYY.MM.DD")}
                            </span>
                          </dd>
                        </dl>
                      </Link>
                    </li>
                  ))}
                </S.BoardCardList>
              ) : (
                <EmptyCard />
              )}
              <CardTail line>
                <Paging
                  totalCount={boards?.totalElements}
                  currentPage={searchCondition.page}
                  size={8}
                  handleChangePage={handleChangePage}
                />
              </CardTail>
            </>
          )}
        </S2.CardInner>
      </S2.CardCol>
    </S2.CardRow>
  );
}

export default EventList;
