import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { Beforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import * as Yup from "yup";
import { useGetClinicInfo, useUpdateClinicInfo } from "../../../apis/doctorApi";
import { deleteMedit } from "../../../apis/meditLinkApi";
import { useGetCouponList } from "../../../apis/paymentApi";
import { isOnlyMeditConnect, meditConnect } from "../../../atoms/meditAtom";
import { userInfo, userRole } from "../../../atoms/userAtom";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick, FieldMessageSuccess } from "../../../components/element/FieldMessage/FieldMessage";
import { FormControlsBox } from "../../../components/element/Form/FormLayout";
import * as S2 from "../../../components/element/Form/style/Form.style";
import { IK } from "../../../utils/i18n/keyStore";
import { eachUploader } from "../../../utils/limitUpload";
import { AccountTabType } from "../AccountTabType";
import SkeletonAccount from "../SkeletonAccount";
import ApplyCouponModal from "./ApplyCouponModal";

function DentistInfo() {
  const { t } = useTranslation(["translation"]);

  /**사용자 정보 가져오기 */
  const [user, setUser] = useRecoilState(userInfo);
  const countryCode = user.countryCode;
  const role = useRecoilValue(userRole);

  /**치과 정보 조회 */
  const { data, isLoading } = useGetClinicInfo();
  const dentistInfo = data?.data;

  /**쿠폰모달  */
  const [couponModal, setCouponModal] = useState(false);
  const openCouponModal = () => {
    setCouponModal(true);
  };

  /**쿠폰 내역 조회 */
  const { data: couponData, isLoading: isCouponLoading } = useGetCouponList(couponModal);

  const clinicMutation = useUpdateClinicInfo();

  const nav = useNavigate();

  /**연동 후 종료 여부 */
  const setIsOnlyMeditConnect = useSetRecoilState(isOnlyMeditConnect);
  /**메딧링크 연동 여부 */
  const [meditConnet, setMeditConnet] = useRecoilState(meditConnect);

  const validationSchema = Yup.object().shape({
    shipAddressId: Yup.string().required(t(IK.shipping_address_required)),
    billAddressId: Yup.string().required(t(IK.billing_address_required)),
    tel: Yup.string().required(t(IK.clinic_number_required)),
  });

  useEffect(() => {
    if (!isLoading && dentistInfo) setMeditConnet(dentistInfo?.medit);
    return () => {
      setIsOnlyMeditConnect(false);
    };
  }, [isLoading, dentistInfo, setIsOnlyMeditConnect, setMeditConnet]);

  //윈도우 객체에 함수 추가
  /**메딧링크 연동 여부 */
  window.updateParentState = () => {
    setMeditConnet(true);
  };

  const handleSubmit = (values) => {
    try {
      Notiflix.Loading.standard("");
      const formData = new FormData();
      for (let key in values) {
        if (values[key]) {
          formData.append(key, values[key]);
        }
      }
      clinicMutation.mutate(formData, {
        onSuccess: (data) => {
          if (formData.has("logo")) {
            setUser((prev) => ({
              ...prev,
              logoPath: data.data.data.logo.distFileName,
            }));
          }
          Notiflix.Report.success(t(IK.success_save), "", t(IK.confirm));
        },
        onError: () => {
          Notiflix.Report.failure(t(IK.fail_save), "", t(IK.confirm));
        },
      });
    } finally {
      Notiflix.Loading.remove();
    }
  };

  /**메딧링크 연동 버튼 */
  const onMedit = () => {
    if (meditConnet) {
      deleteMedit()
        .then((res) => {
          setMeditConnet(false);
          Notiflix.Notify.success(t(IK.medit_clear));
        })
        .catch((res) => Notiflix.Report.failure(t(IK.again_message), t(IK.again_message), t(IK.confirm)));
    } else {
      const medit = process.env.REACT_APP_MEDIT + window.origin + "/medit/callback";
      setIsOnlyMeditConnect(true);
      window.open(`${medit}`, "_blank", "width=630,height=560");
    }
  };

  return (
    <Beforeunload onBeforeunload={() => ""}>
      <CardInnerLayout>
        <AccountTabType now="치과정보" />
        <CardTitle required />
        {isLoading ? (
          <SkeletonAccount />
        ) : (
          <>
            <Formik
              initialValues={{
                shipAddressId: dentistInfo?.shipAddress?.addressId || "",
                billAddressId: dentistInfo?.billAddress?.addressId || "",
                tel: dentistInfo?.tel || "",
                logo: "",
                businessLicense: "",
                businessLicenseNumber: dentistInfo?.businessLicenseNumber || "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnMount={true}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <S2.FormControlsColumn $large>
                    {/* 배송지 */}
                    <FormControlsBox required title={t(IK.shipping_address)}>
                      <S2.FormControlsFlex>
                        <Field name="shipAddressId">
                          {({ field }) => (
                            <S2.FormControls $select as="select" {...field}>
                              {dentistInfo.addressList?.map((address, index) => (
                                <option key={index} value={address.addressId}>
                                  {address.addressName}
                                </option>
                              ))}
                            </S2.FormControls>
                          )}
                        </Field>
                        <S2.FormControlsBtn type="button" onClick={() => nav("/account/mypage/address-manage")}>
                          {t(IK.address_management)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>
                      <FieldMessageErrorClick name="shipAddressId" />
                    </FormControlsBox>

                    {/* 청구지 */}
                    <FormControlsBox required title={t(IK.billing_address)}>
                      <Field name="billAddressId">
                        {({ field }) => (
                          <S2.FormControls $select as="select" {...field}>
                            {dentistInfo.addressList?.map((address, index) => (
                              <option key={index} value={address.addressId}>
                                {address.addressName}
                              </option>
                            ))}
                          </S2.FormControls>
                        )}
                      </Field>
                      <FieldMessageErrorClick name="billAddressId" />
                    </FormControlsBox>

                    {/* 치과연락처 */}
                    <FormControlsBox required title={t(IK.clinic_number)}>
                      <Field as={S2.FormControls} type="text" name="tel" maxLength="13" />
                      <FieldMessageErrorClick name="tel" />
                    </FormControlsBox>

                    {/* 치과로고 */}
                    <FormControlsBox title={t(IK.logo)}>
                      <S2.FormControlsFlex>
                        <S2.FormControls accept="image/*" type="file" id="logo" name="logo" onChange={(event) => eachUploader(event, setFieldValue, "logo", t)} />
                        <S2.FormControlsBtn as="label" htmlFor="logo">
                          {t(IK.file_btn)}
                        </S2.FormControlsBtn>
                      </S2.FormControlsFlex>
                    </FormControlsBox>

                    {countryCode === "01" && (
                      <>
                        {/* 사업자 등록증 */}
                        <FormControlsBox title={t(IK.business_license)}>
                          <S2.FormControlsFlex>
                            <S2.FormControls type="file" id="businessLicense" name="businessLicense" onChange={(event) => eachUploader(event, setFieldValue, "businessLicense", t)} />
                            <S2.FormControlsBtn as="label" htmlFor="businessLicense">
                              {t(IK.file_btn)}
                            </S2.FormControlsBtn>
                          </S2.FormControlsFlex>

                          {dentistInfo?.businessLicense?.distFileName && <FieldMessageSuccess text={`${t(IK.business_license)} ${t(IK.registration)} ${t(IK.compelete)}`} />}
                        </FormControlsBox>

                        {/* 사업자등록번호 */}
                        <FormControlsBox title={t(IK.business_number)}>
                          <Field as={S2.FormControls} type="number" name="businessLicenseNumber" />
                        </FormControlsBox>
                      </>
                    )}

                    {/* 메딧링크 연동 */}
                    <FormControlsBox title={t(IK.mditlink_peristalsis)}>
                      <S.StyledButton as="button" type="button" {...(meditConnet ? { $secondary: true } : { $primary: true })} onClick={onMedit}>
                        {meditConnet ? t(IK.peristalsis_clear) : t(IK.peristalsis_do)}
                      </S.StyledButton>
                    </FormControlsBox>

                    {countryCode === "01" && user.doctorType !== "DENTIS" && !role?.STAFF && (
                      // 사용 가능한 쿠폰
                      <FormControlsBox title={t(IK.available_coupons)}>
                        <S2.FormControlsFlex>
                          {dentistInfo?.couponCount}
                          {t(IK.pieces)}
                          <S.StyledSmallButton as="button" type="button" onClick={openCouponModal}>
                            {t(IK.coupon_box)}
                          </S.StyledSmallButton>
                        </S2.FormControlsFlex>
                      </FormControlsBox>
                    )}
                  </S2.FormControlsColumn>

                  <CardTail line>
                    <S.ButtonLtBox>
                      <S.StyledButton as="button" $primary type="submit">
                        {t(IK.save)}
                      </S.StyledButton>
                    </S.ButtonLtBox>
                  </CardTail>
                </Form>
              )}
            </Formik>
          </>
        )}

        <ApplyCouponModal couponModal={couponModal} setCouponModal={setCouponModal} isCouponLoading={isCouponLoading} couponList={couponData?.data} />
      </CardInnerLayout>
    </Beforeunload>
  );
}

export default DentistInfo;
