import { css, styled } from "styled-components";

// 전체 컨테이너
export const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 89px);
  margin: 20px 20px 0 280px;
`;

// 뱃지
export const CountBadge = styled.span`
  display: inline-block;
  min-width: 25px;
  min-height: 19px;
  line-height: 1;
  padding: 4px 6px;
  border-radius: 7px;
  background-color: ${(props) => (props.$red ? props.theme.colors.red600 : props.theme.colors.blue400)};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.18) 0px 1px 2px;
  text-align: center;
  font-size: 11px;
  color: ${(props) => props.theme.colors.white};
`;

export const ContentBox = styled.div`
  ${(props) =>
    props.$large &&
    css`
      &:not(:first-child) {
        margin-top: 40px;
      }
    `}

  ${(props) =>
    props.$regular &&
    css`
      &:not(:first-child) {
        margin-top: 30px;
      }
    `}

    ${(props) =>
    props.$small &&
    css`
      &:not(:first-child) {
        margin-top: 15px;
      }
    `}
`;

export const ContentLine = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 7px solid ${(props) => props.theme.colors.gray200};
  }
`;

export const ContentScroll = styled.div`
  overflow: auto;
  max-height: ${(props) => (props.$small ? "68px" : props.$regular ? "160px" : props.$large ? "650px" : "100px")};
`;

export const FilterToggleBtn = styled.button`
  position: absolute;
  top: 77px;
  right: 40px;
  z-index: 1;
  line-height: 1;
  padding: 8px 25px;
  border-radius: 7px 7px 0 0;
  background-color: ${(props) => props.theme.colors.blue400};
  color: ${(props) => props.theme.colors.white};
  i {
    margin-left: 5px;
  }
`;

export const FilterList = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    img {
      max-width: 100%;
      max-height: 100%;
      cursor: pointer;
    }
  }
`;

export const ListGroup = styled.dl`
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 5px;
  overflow: hidden;
  dt {
    padding: 13px 20px;
    border-bottom: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.gray300};
    font-size: 18px;
    font-weight: 600;
    p {
      font-size: 15px;
      font-weight: 400;
    }
  }
  dd {
    padding: 20px;
  }
`;

export const TextDiv = styled.span`
  ${(props) =>
    props.$textColorBlue400 &&
    css`
      color: ${(props) => props.theme.colors.blue400};
    `};

  ${(props) =>
    props.$textColorRed600 &&
    css`
      color: ${(props) => props.theme.colors.red600};
    `};

  ${(props) =>
    props.$textSize24 &&
    css`
      font-size: 24px;
    `};

  ${(props) =>
    props.$textBold800 &&
    css`
      font-weight: 800;
    `};

  ${(props) =>
    props.$textBold600 &&
    css`
      font-weight: 600;
    `};

  ${(props) =>
    props.$textpreline &&
    css`
      white-space: pre-line;
      word-break: break-all;
    `};

  ${(props) =>
    props.$textUnderline &&
    css`
      text-decoration: underline;
    `};
`;

export const LanguageBtn = styled.button`
  display: flex;
  align-items: center;
  gap: 7px;
  color: ${(props) => props.theme.colors.gray600};
`;

// ImageViewer
export const ImageViewerBox = styled.div`
  .styles-module_wrapper__1I_qj {
    z-index: 5;
  }
`;
