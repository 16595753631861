import styled from "styled-components";
import { Link } from "react-router-dom";
import LoginBg from "../../../assets/images/login/login_bg3.jpg";
import { LanguageBtn } from "../../../styles/Common";
import { SelectionItemLabel } from "../../../components/element/SelectionControls/style/SelectionControls.style";

export const LoginContainer = styled.div`
  position: relative;
  height: 100vh;
  background: url(${LoginBg}) no-repeat center/cover;
`;

export const LoginBox = styled.form`
  position: absolute;
  top: 50%;
  right: 290px;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 680px;
  margin-top: -340px;
  background-color: ${(props) => props.theme.colors.white};
  padding: 100px 40px 55px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  ${LanguageBtn} {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
`;

export const LoginLogo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;

export const LoginFormBox = styled.div`
  margin-bottom: 13px;
`;

export const LoginFormFocus = styled.div`
  position: absolute;
  display: block;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  top: -1px;
  left: -1px;
  pointer-events: none;
  border: 1px solid ${(props) => props.theme.colors.blue400};
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;
  transform: scaleX(1.1) scaleY(1.3);
`;

export const LoginForm = styled.div`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  height: 70px;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  border-radius: 10px;
  input {
    width: 100%;
    height: 100%;
    background: transparent;
    font-size: 15px;
    padding: 0 26px;
    transition: all 0.3s;
    border-radius: 10px;
    border: 0;
    &:focus + ${LoginFormFocus} {
      visibility: visible;
      opacity: 1;
      transform: scale(1);
    }
  }
`;

export const LoginContact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a,
  ${SelectionItemLabel} {
    color: ${(props) => props.theme.colors.gray600};
  }
  a:hover {
    color: ${(props) => props.theme.colors.blue400};
  }
`;

export const LoginAccount = styled(Link)`
  position: relative;
  padding-right: 12px;
  margin-right: 12px;
  &:before {
    content: "";
    position: absolute;
    top: 2px;
    right: -2px;
    width: 1px;
    height: 12px;
    background-color: ${(props) => props.theme.colors.gray400};
  }
`;

export const LoginButton = styled.button`
  width: 100%;
  height: 55px;
  line-height: 55px;
  padding: 0 20px;
  margin-top: 30px;
  border-radius: 10px;
  background: ${(props) => props.theme.colors.blue400};
  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.white};
  &:hover {
    opacity: 0.8;
  }
`;

export const LoginBottom = styled.div`
  margin-top: auto;
  text-align: center;
  color: ${(props) => props.theme.colors.gray600};
  div {
    margin-bottom: 10px;
    button {
      color: ${(props) => props.theme.colors.blue400};
      &:not(:last-child) {
        position: relative;
        padding-right: 12px;
        margin-right: 12px;
        &:before {
          content: "";
          position: absolute;
          top: 4px;
          right: -2px;
          width: 1px;
          height: 12px;
          background-color: ${(props) => props.theme.colors.gray400};
        }
      }
    }
  }
`;
