import axios from "axios";

/**OTT 코드 전송*/
export const postAdminOtt = async (ott) => {
  const response = await axios.post(
    `/api/auth/admin-login`,
    { ott },
    {
      headers: {
        "Content-type": "application/json",
      },
    }
  );
  return response;
};
