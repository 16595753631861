import axios from "axios";
import { Field, Formik } from "formik";
import Notiflix from "notiflix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import * as Yup from "yup";
import { useGetPopup } from "../../apis/popupApi";
import { userInfo, userRole } from "../../atoms/userAtom";
import MainModalWrapper from "../../components/Modal/MainModal";
import Modal from "../../components/Modal/Modal";
import Privacy from "../../components/Privacy/Privacy";
import Terms from "../../components/Terms/Terms";
import { FieldMessageErrorClick } from "../../components/element/FieldMessage/FieldMessage";
import { LogoType1 } from "../../components/element/Logo/Logo";
import * as S3 from "../../components/element/SelectionControls/style/SelectionControls.style";
import * as S2 from "../../styles/Common";
import { setAllToken } from "../../utils/handleToken";
import { IK } from "../../utils/i18n/keyStore";
import * as S from "./style/Login.style";

function Login() {
  const { t, i18n } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const rememberMeChecked = localStorage.getItem("rememberMe") ? true : false;
  const [rememberMe, setRememberMe] = useState(rememberMeChecked);
  const initialId = rememberMeChecked ? localStorage.getItem("rememberMe") : "";

  /**사용자 정보 가져오기 */
  const setUserInfo = useSetRecoilState(userInfo);
  const setUserRole = useSetRecoilState(userRole);

  /**언어 설정을 변경한 유저의 값 가져오기 (리코일은 로그아웃시 지워버림)*/
  const commonLang = localStorage.getItem("langInfo") || "ko";

  /**사용자 언어 가져오기 */
  const [langInfo, setLangInfo] = useState(commonLang);

  useEffect(() => {
    setLangInfo(commonLang);
  }, [setLangInfo, commonLang]);

  const onChangeLang = () => {
    const newLangInfo = langInfo === "ko" ? "en" : "ko";
    setLangInfo(newLangInfo);
    localStorage.setItem("langInfo", newLangInfo);
  };

  /**팝업 리스트 조회 */
  const { data: popupData } = useGetPopup();

  /**글로벌 버젼 스타일 조정 */
  useEffect(() => {
    i18n.changeLanguage(langInfo).then(() => {
      if (langInfo === "en") {
        document.body.classList.add("global");
      } else {
        document.body.classList.remove("global");
      }
    });
  }, [langInfo, i18n]);

  /**아이디 기억 */
  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
  };

  /**공백 방지 */
  const handleKeyDown = (event, setFieldValue) => {
    if (event.key === " ") {
      event.preventDefault();
      setFieldValue(event.currentTarget.name, event.currentTarget.value.replace(/\s/g, ""));
    }
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(t(IK.id_required)),
    password: Yup.string().required(t(IK.password_required)),
  });

  /**로그인 요청 */
  const onLoginSubmit = (values, { setSubmitting }) => {
    const dataToSubmit = {
      username: values.username,
      password: values.password,
      accountType: "DOCTOR",
    };
    axios
      .post(`/api/auth/login`, dataToSubmit)
      .then((response) => {
        if (response.status === 200) {
          const Logindata = response.data.data;
          setAllToken(Logindata.accessToken);
          setUserInfo(Logindata.roleInfo?.DOCTOR || Logindata.roleInfo?.STAFF);
          setUserRole(Logindata.roles);
          if (rememberMe === true) {
            window.localStorage.setItem("rememberMe", values.username);
          } else {
            localStorage.removeItem("rememberMe");
          }
          navigate("/patient/list");
        }
      })
      .catch((err) => {
        if (err?.response?.status === 423) Notiflix.Report.failure("", t(IK.id_lock), t(IK.confirm));
        if (err?.response?.status === 422) Notiflix.Report.failure(t(IK.id_admin), t(IK.id_auth), t(IK.confirm));
        Notiflix.Report.failure(t(IK.fail_credential), t(IK.recheck_message), t(IK.confirm));
      });
    setSubmitting(false);
  };

  const [privacyOpen, setPrivacyOpen] = useState(false);
  const openPrivacy = () => {
    setPrivacyOpen(true);
  };
  const closePrivacy = () => {
    setPrivacyOpen(false);
  };

  const [termsOpen, setTermsOpen] = useState(false);
  const openTerms = () => {
    setTermsOpen(true);
  };
  const closeTerms = () => {
    setTermsOpen(false);
  };

  return (
    <>
      <Formik
        initialValues={{
          username: initialId,
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onLoginSubmit}
      >
        {({ setFieldValue, handleSubmit, isSubmitting, errors }) => (
          <S.LoginContainer>
            <S.LoginBox onSubmit={handleSubmit} action={"/api/login"}>
              <S.LoginLogo>
                <LogoType1 />
              </S.LoginLogo>

              <S.LoginFormBox>
                <S.LoginForm>
                  <Field type="text" id="username" name="username" onKeyDown={(event) => handleKeyDown(event, setFieldValue)} />
                  <S.LoginFormFocus />
                </S.LoginForm>
                <FieldMessageErrorClick name="username" />
              </S.LoginFormBox>

              <S.LoginFormBox>
                <S.LoginForm>
                  <Field type="password" id="password" name="password" onKeyDown={(event) => handleKeyDown(event, setFieldValue)} />
                  <S.LoginFormFocus />
                </S.LoginForm>
                <FieldMessageErrorClick name="password" />
              </S.LoginFormBox>

              <S.LoginContact>
                <S3.SelectionItem $Small>
                  <S3.SelectionItemLabel>
                    <S3.SelectionItemInput $checkbox type="checkbox" name="remember" onChange={handleRememberMe} checked={rememberMe} />
                    <S3.SelectionItemSpan>{t("rememberme")}</S3.SelectionItemSpan>
                  </S3.SelectionItemLabel>
                </S3.SelectionItem>
                <div>
                  <S.LoginAccount to="/join">{t("create_account")}</S.LoginAccount>
                  <Link to="/find-id-form">{t("find_id_pw")}</Link>
                </div>
              </S.LoginContact>

              <S.LoginButton type="submit" disabled={isSubmitting}>
                {t("login")}
              </S.LoginButton>

              <S.LoginBottom>
                {langInfo === "ko" && (
                  <div>
                    <button type="button" onClick={openPrivacy}>
                      {t("privacy")}
                    </button>
                    <button type="button" onClick={openTerms}>
                      {t("terms_of_use")}
                    </button>
                  </div>
                )}
                <p>{t(IK.tns_copyright)}</p>
              </S.LoginBottom>
              <S2.LanguageBtn type="button" onClick={onChangeLang}>
                {t(IK.language)}
                <S2.CountBadge>{langInfo?.toUpperCase()}</S2.CountBadge>
              </S2.LanguageBtn>
            </S.LoginBox>
          </S.LoginContainer>
        )}
      </Formik>
      {/* 개인정보처리방침 */}
      <Modal open={privacyOpen} close={closePrivacy} header={t(IK.privacy)}>
        <Privacy />
      </Modal>

      {/* 이용약관 */}
      <Modal open={termsOpen} close={closeTerms} header={t(IK.terms_of_use)} headerSub={"(2023.07.05 " + t(IK.partial_revision) + ")"}>
        <Terms />
      </Modal>

      {/*팝업 디스플레이 */}
      <MainModalWrapper modals={popupData} />
    </>
  );
}

export default Login;
