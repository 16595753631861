import i18n from "i18next";

import { initReactI18next } from "react-i18next";

//import detector from "i18next-browser-languagedetector";

import jp from "../../assets//locales/ja-JP/translation.json";
import ko from "../../assets//locales/ko-KR/translation.json";
import en from "../../assets/locales/en-US/translation.json";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ko: {
        translation: ko,
      },
      en: {
        translation: en,
      },
      jp: {
        translation: jp,
      },
    },
    lng: "ko", //언어 감지기를 상요하는 경우 옵션 정의 X
    fallbackLng: "ko",
    detection: {
      // languagedetector option
      order: ["querystring", "htmlTag", "cookie"], // detect 우선순위
      lookupQueryString: "lang", // ?lang=
      lookupCookie: "i18n_lang", // cookie name
    },
    debug: false,
    saveMissing: true, //변환되지않는 키를 엔드포인트로 보냅니다.
    defaultNS: false,
    // keySeparator: false, //메세지 형식에서 키를 사용하지 않습니다.
    //ns: false,
    returnEmptyString: false,
    interpolation: {
      escapeValue: false,
    },
  })
  .then(function (response) {
    //console.log(response);
  });

export default i18n;
