import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import * as S from "../../../../components/element/BoardSearch/style/BoardSearch.style";
import * as S2 from "../../../../components/element/Form/style/Form.style";
import * as S3 from "../../../../components/element/Button/style/ButtonLayout.style";
import * as S4 from "../../../../styles/Common";
import * as S5 from "../../../../components/element/SelectionControls/style/SelectionControls.style";
import { IK } from "../../../../utils/i18n/keyStore";
import { TitleV3 } from "../../../../components/element/Title/TitleText";

const SearchFilter = ({ searchCondition, setSearchCondition }) => {
  const { t } = useTranslation(["translation"]);

  /**case번호 입력 */
  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      if (event.target.value) {
        setSearchCondition((prev) => ({
          ...prev,
          case: event.target.value,
          page: 1,
        }));
      } else {
        setSearchCondition((prev) => {
          delete prev.case;
          return { ...prev, page: 1 };
        });
      }
    }
  };

  /**case번호 버튼으로 검색 */
  const handleOnClickSearchBtn = (e) => {
    setSearchCondition((prev) => ({
      ...prev,
      case: document.getElementById("caseNumber").value,
      page: 1,
    }));
  };

  /**성별 선택 */
  const handleOnChangeGender = (e) => {
    const checkboxes = Array.from(document.getElementsByName("genderArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      gender: values.join(";"),
      page: 1,
    }));
  };

  /**치료 옵션 선택 */
  const handleOnClickCheckBox = (e) => {
    const checkboxes = Array.from(document.getElementsByName("packagesArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      packages_list: values.join(";"),
      page: 1,
    }));
  };

  /**임상상태 선택 */
  const handleOnClickClinicalCondition = (e) => {
    const checkboxes = Array.from(document.getElementsByName("conditionsArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      conditions_list: values.join(";"),
      page: 1,
    }));
  };

  /**인종 선택 */
  const handleOnChangeRace = (e) => {
    const checkboxes = Array.from(document.getElementsByName("raceArray"));
    const values = checkboxes.filter((item) => item.checked).map((item) => item.value);
    setSearchCondition((prev) => ({
      ...prev,
      race: values.join(";"),
      page: 1,
    }));
  };

  /**case 번호 입력값 가져오기 */
  const inputRef = useRef();

  /**초기화*/
  const handleOnClickReset = () => {
    setSearchCondition({
      size: 8,
      page: 1,
    });
    inputRef.current.value = "";
  };

  const packages = [
    { value: "RP", label: t(IK.serafin) + " R Passive" },
    { value: "R", label: t(IK.serafin) + " R Active" },
    { value: "S10", label: t(IK.serafin) + " 10" },
    { value: "S20", label: t(IK.serafin) + " 20" },
    { value: "SR", label: t(IK.SR) },
    { value: "SAP", label: t(IK.SAP) },
    { value: "Si", label: t(IK.Si) },
    { value: "SiAP", label: t(IK.SiAP) },
  ];

  const conditions = [
    { value: "class1", label: "Class I" },
    { value: "class2", label: "Class II div 1" },
    { value: "class3", label: "Class II div 2" },
    { value: "class4", label: "Class III" },
    { value: "crowding", label: t(IK.crowding) },
    { value: "spacing", label: t(IK.Interdental_space) },
    { value: "narrowArch", label: t(IK.narrow_dental_arch) },
    { value: "deepBite", label: t(IK.deepbite) },
    { value: "openbiteVerticality", label: t(IK.open_bite_vertical) },
    { value: "openbiteOverjet", label: t(IK.open_bite_horizontal) },
    { value: "abteriorCrossbite", label: t(IK.anterior_crossbite) },
    { value: "posteriorCrossbite", label: t(IK.posterior_crossbite) },
    { value: "flaredTeeth", label: t(IK.prolapse_of_anterior_teeth) },
    { value: "unevenSmile", label: t(IK.smile_line_needs_improvement) },
    { value: "asymmetric", label: t(IK.asymmetry) },
    { value: "occlusalPlaneRight", label: t(IK.Inclined_occlusal_plane_right) },
    { value: "occlusalPlaneLeft", label: t(IK.Inclined_occlusal_plane_left) },
    { value: "misshapenTeeth", label: t(IK.missing) },
    { value: "occlusalPlane", label: t(IK.abnormally_shaped_teeth) },
  ];

  const genderList = [
    { value: "male", label: t(IK.male) },
    { value: "female", label: t(IK.female) },
  ];

  const raceList = [
    { value: "Asian", label: t(IK.asian) },
    { value: "African", label: t(IK.african) },
    { value: "Caucasian", label: t(IK.caucasian) },
  ];

  return (
    <>
      <S4.ContentBox $regular>
        <TitleV3 title={t(IK.case_number)} />
        <S.BoardSearchForm>
          <S2.FormControls
            $full
            type="number"
            id="caseNumber"
            name="caseNumber"
            onKeyDown={handleOnKeyDown}
            placeholder={t(IK.case_number_placeholder)}
            defaultValue={searchCondition?.case}
            ref={inputRef}
          />
          <button type="button" onClick={handleOnClickSearchBtn}>
            <i className="ri-search-line"></i>
          </button>
        </S.BoardSearchForm>
      </S4.ContentBox>

      <S4.ContentBox $regular>
        <TitleV3 title={t(IK.gender)} />
        <S5.SelectionItemList>
          {genderList.map((genders) => (
            <S5.SelectionItem key={genders.value}>
              <S5.SelectionItemLabel>
                <S5.SelectionItemInput
                  $checkbox
                  type="checkbox"
                  name="genderArray"
                  onChange={handleOnChangeGender}
                  value={genders.value}
                  checked={searchCondition?.gender?.split(";").includes(genders.value) || false}
                />
                <S5.SelectionItemSpan>{genders.label}</S5.SelectionItemSpan>
              </S5.SelectionItemLabel>
            </S5.SelectionItem>
          ))}
        </S5.SelectionItemList>
      </S4.ContentBox>

      <S4.ContentBox $regular>
        <TitleV3 title={t(IK.race)} />
        <S5.SelectionItemList>
          {raceList.map((races) => (
            <S5.SelectionItem key={races.value}>
              <S5.SelectionItemLabel>
                <S5.SelectionItemInput
                  $checkbox
                  type="checkbox"
                  name="raceArray"
                  onChange={handleOnChangeRace}
                  value={races.value}
                  checked={searchCondition?.race?.split(";").includes(races.value) || false}
                />
                <S5.SelectionItemSpan>{races.label}</S5.SelectionItemSpan>
              </S5.SelectionItemLabel>
            </S5.SelectionItem>
          ))}
        </S5.SelectionItemList>
      </S4.ContentBox>

      <S4.ContentBox $regular>
        <TitleV3 title={t(IK.treatment_option)} />
        <S4.ContentScroll $regular>
          <S2.FormControlsColumn>
            {packages.map((pkg) => (
              <S5.SelectionItem key={pkg.value}>
                <S5.SelectionItemLabel>
                  <S5.SelectionItemInput
                    $checkbox
                    type="checkbox"
                    name="packagesArray"
                    onChange={handleOnClickCheckBox}
                    value={pkg.value}
                    checked={searchCondition?.packages_list?.split(";").includes(pkg.value) || false}
                  />
                  <S5.SelectionItemSpan>{pkg.label}</S5.SelectionItemSpan>
                </S5.SelectionItemLabel>
              </S5.SelectionItem>
            ))}
          </S2.FormControlsColumn>
        </S4.ContentScroll>
      </S4.ContentBox>

      <S4.ContentBox $regular>
        <TitleV3 title={t(IK.clinical_condition)} />
        <S4.ContentScroll $regular>
          <S2.FormControlsColumn>
            {conditions.map((condition) => (
              <S5.SelectionItem key={condition.value}>
                <S5.SelectionItemLabel>
                  <S5.SelectionItemInput
                    $checkbox
                    type="checkbox"
                    name="conditionsArray"
                    onChange={handleOnClickClinicalCondition}
                    value={condition.value}
                    checked={searchCondition?.conditions_list?.split(";").includes(condition.value) || false}
                  />
                  <S5.SelectionItemSpan>{condition.label}</S5.SelectionItemSpan>
                </S5.SelectionItemLabel>
              </S5.SelectionItem>
            ))}
          </S2.FormControlsColumn>
        </S4.ContentScroll>
      </S4.ContentBox>

      <S4.ContentBox $regular>
        <S3.StyledButton as="button" $primary $full type="button" onClick={handleOnClickReset}>
          <i className="ri-restart-line"></i> {t(IK.reset)}
        </S3.StyledButton>
      </S4.ContentBox>
    </>
  );
};

export default SearchFilter;
