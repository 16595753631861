import * as React from "react";
import { useState } from "react";
import { useSearch } from "../../../Hooks/useSearch";
import { useGetGalleryList } from "../../../apis/galleryApi";
import { CardInnerLayout, CardTail } from "../../../components/Layout/CardLayout/CardLayout";
import * as S2 from "../../../components/Layout/CardLayout/style/CardLayout.style";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";
import { changeParam, urlParamToObject } from "../../../utils/searchUtils";
import Card from "./Section/Card";
import SearchFilter from "./Section/SearchFilter";
import { SkeletonCaseList } from "./SkeletonCaseList";
import * as S from "./style/ClinicalCases.style";

function ClinicalCasesList() {
  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(window.location.search ? urlParamToObject() : { page: 1 });

  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  /**데이터 조회 */
  const { data, isLoading, isError } = useGetGalleryList(changeParam(searchCondition, "gallery"));
  const clinicCaseList = data?.data;

  /**페이지 변경 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  return (
    <CardInnerLayout>
      <S2.CardFlex>
        <S2.CardColWd $wd300>
          <SearchFilter searchCondition={searchCondition} setSearchCondition={setSearchCondition} />
        </S2.CardColWd>

        <S2.CardColWd $full>
          {isLoading || isError ? (
            <SkeletonCaseList />
          ) : (
            <>
              {clinicCaseList?.content?.length > 0 ? (
                <S.CaseList>
                  {clinicCaseList?.content?.map((item) => (
                    <Card key={item.clinicalId} item={item} />
                  ))}
                </S.CaseList>
              ) : (
                <EmptyCard />
              )}
              <CardTail line>
                <Paging totalCount={clinicCaseList.totalElements} currentPage={searchCondition.page} size={8} handleChangePage={handleChangePage} />
              </CardTail>
            </>
          )}
        </S2.CardColWd>
      </S2.CardFlex>
    </CardInnerLayout>
  );
}

export default ClinicalCasesList;
