import { NavLink, useLocation } from "react-router-dom";
import * as S from "./style/Sidebar.style";

const SidebarNavi = ({ navi }) => {
  const { pathname } = useLocation();

  return (
    <li>
      <NavLink to={navi.nav}>
        <S.MenuIco>
          <i className={navi.icon} />
        </S.MenuIco>
        <S.MenuTxt>{navi.title}</S.MenuTxt>
        {navi.depth && navi.depth.length > 0 && (
          <S.MenuArrow>
            <i className="ri-arrow-drop-right-line" />
          </S.MenuArrow>
        )}
      </NavLink>
      {navi?.depth?.length > 0 && (
        <S.SidebarNavDepth2>
          {navi?.depth?.map(
            (sub, i) =>
              sub.roleAuth && (
                <li key={sub.title}>
                  <NavLink className={({ isActive }) => (isActive || (i === 0 && pathname === navi.nav) ? "active" : "")} to={sub.nav}>
                    <S.MenuIco>
                      <i className="ri-arrow-drop-right-line" />
                    </S.MenuIco>
                    <S.MenuTxt>{sub.title}</S.MenuTxt>
                  </NavLink>
                </li>
              )
          )}
        </S.SidebarNavDepth2>
      )}
    </li>
  );
};

export default SidebarNavi;
