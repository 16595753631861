import { Field, Form, Formik } from "formik";
import Notiflix from "notiflix";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import useCalculateTotal from "../../../Hooks/useCalculateTotal";
import useLoadScripts from "../../../Hooks/useLoadScripts";
import { useGetDoctorInfo } from "../../../apis/doctorApi";
import { useFailModulePayment, useGetCouponList, useGetPaymentList, useModulePayment, usePrePaidpayment, usePrepayment } from "../../../apis/paymentApi";
import { CardInnerLayout, CardTail, CardTitle } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import * as S4 from "../../../components/element/Button/style/ButtonLayout.style";
import { FieldMessageErrorClick } from "../../../components/element/FieldMessage/FieldMessage";
import * as S2 from "../../../components/element/Table/style/table.style";
import * as S5 from "../../../components/element/Title/style/Title.style";
import * as S3 from "../../../styles/Common";
import { enterKeyDown } from "../../../utils/enterKeyDown";
import { IK } from "../../../utils/i18n/keyStore";
import { formatCurrency, payMethod } from "../../../utils/paymentUtils";
import { seraphinOption } from "../../../utils/studyUtils";
import CouponModal from "./CouponModal";
import PaymentDetailModal from "./PaymentDetailModal";
import SkeletonBill from "./SkeletonBill";

function BillingPayment() {
  const { billingId } = useParams();
  const { state } = useLocation();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  const { data: doctorData, isLoading: isDoctorLoading } = useGetDoctorInfo();
  const doctorInfo = doctorData?.data;

  /**상세 페이지 조회 */
  const { data, isLoading } = useGetPaymentList(billingId);
  const [billsInfo, setBillsInfo] = useState([]);

  /**적용했다가 취소할 경우를 대비해 적용한 쿠폰 정보 저장 */
  const [applyCoupon, setApplyCoupon] = useState([]);
  /**애스크로 결제 */
  const [isEscrow, setIsEscrow] = useState(false);

  /**총금액 계산 */
  const [totals, setTotals] = useState({
    discountPrice: 0,
    paymentPrice: 0,
    salePrice: 0,
    discountCoupon: 0,

    //총합 금액
    totalSalelPrice: 0,
    totalDiscountPrice: 0,
    totalPaymentPrice: 0,
  });

  /**결제 전 아이디 생성 */
  const prepaymentMutation = usePrepayment();
  /**결제금액 0원 처리(선수금, 할인 등) */
  const prepaidMutation = usePrePaidpayment();
  /**결제 모듛 호출 후 성공 */
  const paymetMutation = useModulePayment();
  /**결제 모듛 호출 후 실패 */
  const failPaymetMutation = useFailModulePayment();

  /**처음 마운트시 모두 체크표시, 데이터 담기 */
  useEffect(() => {
    if (!isLoading) {
      setBillsInfo(
        data.map((item) => ({
          ...item,
          check: item.billingDetailStatus === 10, // item.billingDetailStatus가 미결제인 경우에만 check: true를 설정
        }))
      );
    }
  }, [isLoading, data]);

  /**formik 컨트롤 */
  const formikRef = useRef(null);

  // /** 총 결제 금액 변경 시 결제 수단 선택 활성화 **/
  useEffect(() => {
    if (totals.paymentPrice > 0) {
      document.getElementsByName("paymentMethod").forEach((item) => {
        item.disabled = false;
      });
      formikRef.current?.setFieldValue("paymentMethod", "");
    } else {
      document.getElementsByName("paymentMethod").forEach((item) => {
        item.disabled = true;
      });
      formikRef.current?.setFieldValue("paymentMethod", "4");
    }
  }, [totals.paymentPrice]);

  /**총합 금액 계산하기 */
  useCalculateTotal(billsInfo, isLoading, setTotals);

  /**쿠폰 모달 */
  const [couponModal, setCouponModal] = useState(false);
  const [couponId, setCouponId] = useState("");
  const onModalOpen = (id) => {
    setCouponId(id);
    setCouponModal(true);
  };

  console.log(totals);

  /**결제 내역 모달 */
  const [paymentDetailModal, setPaymentDetailModal] = useState({
    isOpen: false,
    paymentId: "",
  });
  const openPaymentDetailModal = (paymentId) => setPaymentDetailModal({ isOpen: true, paymentId });
  const closePaymentDetailModal = () => setPaymentDetailModal({ isOpen: false, paymentId: "" });

  /**쿠폰 내역 조회 */
  const { data: couponData, isLoading: isCouponLoading } = useGetCouponList(couponModal);
  const [couponList, setCouponList] = useState([]);
  useEffect(() => {
    if (!isCouponLoading) {
      setCouponList(couponData?.data);
    }
  }, [isCouponLoading, couponData]);

  /** 체크 박스 단일 선택 */
  const handleSingleCheck = (id) => {
    setBillsInfo((prev) => prev.map((item) => (item.billingDetailId === id ? { ...item, check: !item.check } : item)));
  };

  /** 체크박스 전체 선택 */
  const handleAllCheck = (checked) => {
    setBillsInfo((prev) =>
      prev.map((item) => ({
        ...item,
        check: item.billingDetailStatus === 10 ? checked : item.check,
      }))
    );
  };

  /**쿠폰 취소하기 */
  const removeCouponPrice = (id) => {
    setBillsInfo((prev) => {
      const updatedBillsInfo = [...prev]; // 이전 배열을 복제하여 사용
      const index = updatedBillsInfo.findIndex((item) => item.billingDetailId === id);
      if (index !== -1) {
        const { discountCoupon, couponId, ...rest } = updatedBillsInfo[index];
        updatedBillsInfo[index] = {
          ...rest,
          paymentPrice: rest.paymentPrice + (discountCoupon || 0),
        };
      }
      return updatedBillsInfo;
    });

    const couponToApply = applyCoupon.find((coupon) => coupon.billingDetailId === id);
    if (couponToApply) {
      setCouponList((prevCouponList) => [...prevCouponList, couponToApply].sort((a, b) => a.couponId - b.couponId));
    }
  };

  const validationSchema = Yup.object().shape({
    paymentMethod: Yup.string()
      .required(t(IK.select_pay_methods))
      .test("no-card", t(IK.no_card), (value) => totals.paymentPrice <= 5000000 || value !== "1"),
  });

  /**아임포트 스크립트 동적 추가하기 */
  useLoadScripts();

  const submit = (values) => {
    //체크 항목 검사, 결제 요청이 아닌 애들은 제외
    const checkedBills = billsInfo.filter((item) => item.check);
    if (checkedBills.length < 1) return Notiflix.Report.info("", t(IK.no_check_pay));

    const { paymentPrice, salePrice, discountPrice } = totals;

    const payData = {
      billingId,
      paymentMethod: payMethod[values.paymentMethod],
      paymentTotalPrice: paymentPrice,
      saleTotalPrice: salePrice,
      discountTotalPrice: discountPrice,
      checkedBillings: checkedBills.map(({ billingDetailId, couponId }) => ({
        billingDetailId,
        couponId,
      })),
    };

    if (paymentPrice <= 0) {
      //선결제 api 호출
      prepaidMutation.mutate(payData, {
        onSuccess: (data) => {
          navigate(`/account/billing-list/billing-done`, {
            state: {
              isSussesPayment: true,
            },
          });
        },
        onError: () => {
          Notiflix.Notify.failure(t(IK.again_message));
        },
      });
    } else {
      //아임포트 호출
      paymontIMP(payData, checkedBills);
    }
  };

  const paymontIMP = (payData, checkedBills) => {
    prepaymentMutation.mutate(payData, {
      onSuccess: (data) => {
        const merchantId = data?.data;
        const { IMP } = window;
        IMP.init(process.env.REACT_APP_IMP_USER_CODE);
        const payload = {
          pg: "kcp", // PG사
          escrow: payData.paymentMethod === "card" ? false : isEscrow, //카드결제는 애스크로 결제 false 고정
          pay_method: payData.paymentMethod, // 결제수단
          merchant_uid: merchantId, // 주문번호
          amount: payData.paymentTotalPrice, // 결제금액
          name: `${state.billMonth}${t(IK.month_order_sheet)}`, // 주문명
          buyer_name: doctorInfo.name, // 구매자 이름
          buyer_tel: doctorInfo.phoneNumber, // 구매자 전화번호
          buyer_email: doctorInfo.email, // 구매자 이메일
        };
        //애스크로 결제의 경우 kcpProducts 파라메터가 반드시 세팅되어야 합니다.
        if (isEscrow) {
          payload.kcpProducts = checkedBills.map((bill) => ({
            orderNumber: bill.billingDetailId,
            name: bill.packages,
            quantity: 1,
            amount: bill.paymentPrice - (bill.discountCoupon || 0),
          }));
        }
        IMP.request_pay(payload, (response) => callback(response, payData));
      },
      onError: () => {
        Notiflix.Notify.failure(t(IK.again_message));
      },
    });
  };

  /**아임포트 결제 응답 */
  const callback = (response, payData) => {
    const { success, imp_uid, merchant_uid, error_msg } = response;
    if (success) {
      paymetMutation.mutate(
        {
          impUid: imp_uid,
          merchantUid: merchant_uid,
          checkedBillings: payData.checkedBillings,
        },
        {
          onSuccess: (response) => {
            navigate(`/account/billing-list/billing-done`, {
              state: { isSussesPayment: true, ...response.data },
            });
          },
          onError: () => {
            alert(`${t(IK.admin_error_message)}_${t(IK.fail_billing)}`);
          },
        }
      );
    } else {
      failPaymetMutation.mutate(
        {
          paymentId: merchant_uid,
          errorMsg: error_msg,
        },
        {
          onSuccess: () => {
            alert(`${t(IK.fail_billing)}: ${error_msg}`);
          },
          onError: () => {
            alert(`${t(IK.admin_error_message)}`);
          },
        }
      );
    }
  };

  /**지불 상세 상태코드 표기 */
  const billsStatus = {
    0: t(IK.upcoming_billing), // 결제 예정
    10: t(IK.outstanding), // 미결제 (결제 요청)
    15: t(IK.payment_wait), // 입금 대기
    20: t(IK.complete_billing), // 결제 완료
    60: t(IK.cancle_billing), //결제취소, 안쓰는 상태값
    70: t(IK.full_discount), //전액 할인
    80: t(IK.exclude), // 제외
    90: t(IK.direct_pay), // 직접 결제
    99: t(IK.carryover), // 이월
  };

  const billChecks = billsInfo.filter((item) => item.billingDetailStatus === 10).length;

  const checkedBills = useMemo(() => billsInfo.filter((item) => item.check).length, [billsInfo]);

  return (
    <>
      {isLoading || isDoctorLoading ? (
        <SkeletonBill />
      ) : (
        <CardInnerLayout>
          <CardTitle title={`${t(IK.pay_order)}(#${billingId})`} />
          <S.BoardTable>
            <colgroup>
              {billChecks > 0 && <col width="60px" />}
              <col width="120px" />
              <col width="170px" />
              <col width="200px" />
              <col width="120px" />
              <col width="120px" />
              <col width="120px" />
              <col width="150px" />
              <col width="*" />
            </colgroup>
            <thead>
              <tr>
                {billChecks > 0 && (
                  <th scope="col">
                    <S.BoardTableChk>
                      <label className="label_checkbox">
                        <input
                          type="checkbox"
                          name="selectAll"
                          className="input_checkbox"
                          onChange={(e) => handleAllCheck(e.target.checked)}
                          // 데이터 개수와 체크된 아이템의 개수가 다를 경우 선택 해제 (하나라도 해제 시 선택 해제)
                          checked={billsInfo.filter((item) => item.billingDetailStatus === 10).every((item) => item.check)}
                        />
                      </label>
                    </S.BoardTableChk>
                  </th>
                )}
                <th scope="col">{t(IK.pay_products)}</th>
                <th scope="col">{t(IK.payment_status)}</th>
                <th scope="col">{t(IK.patient_name)}</th>
                <th scope="col">
                  {`${t(IK.pay_products)} ${t(IK.pay_amount)}`}
                  <br />
                  <S3.TextDiv $textColorBlue400>{formatCurrency(totals.totalSalelPrice)}</S3.TextDiv>
                </th>
                <th scope="col">
                  {`${t(IK.discount)} ${t(IK.pay_amount)}`}
                  <br />
                  <S3.TextDiv $textColorRed600>-{formatCurrency(totals.totalDiscountPrice)}</S3.TextDiv>
                </th>
                <th scope="col">{t(IK.able_coupon)}</th>
                <th scope="col">
                  {t(IK.pay_charged)}
                  <br />
                  <S3.TextDiv $textColorBlue400>{formatCurrency(totals.totalPaymentPrice)}</S3.TextDiv>
                </th>
                <th scope="col">{t(IK.pay_notes)}</th>
              </tr>
            </thead>
            <tbody>
              {billsInfo?.map((data) => (
                <tr key={data.billingDetailId}>
                  {billChecks > 0 && (
                    <td>
                      {data?.billingDetailStatus === 10 && (
                        <S.BoardTableChk>
                          {/* 미결제(10) 일 경우만 결제 가능 */}
                          <div className="checkbox">
                            <label className="label_checkbox">
                              <input type="checkbox" name={`select${data.billingDetailId}`} className="input_checkbox" onChange={() => handleSingleCheck(data.billingDetailId)} checked={data.check} />
                            </label>
                          </div>
                        </S.BoardTableChk>
                      )}
                    </td>
                  )}

                  <td>{seraphinOption[data.packages]}</td>
                  <td>
                    {/**결제완료 또는 입금 대기 */}
                    {data?.billingDetailStatus === 20 || data?.billingDetailStatus === 15 ? (
                      <S4.StyledSmallButton as="button" type="button" onClick={() => openPaymentDetailModal(data?.paymentId)}>
                        {`${billsStatus[data?.billingDetailStatus]} (#${data?.paymentId || 0}) `}
                      </S4.StyledSmallButton>
                    ) : (
                      billsStatus[data?.billingDetailStatus]
                    )}
                  </td>
                  <td>
                    {data.firstName} {data.lastName} {data?.koreaName && `(${data.koreaName})`}
                  </td>
                  <td>
                    {formatCurrency(data.salePrice)}
                    {t(IK.krw)}
                  </td>
                  <td>
                    <S3.TextDiv $textColorRed600>
                      -{formatCurrency(data.discountPrice)}
                      {t(IK.krw)}
                    </S3.TextDiv>
                  </td>
                  <td>
                    {data?.billingDetailStatus === 10 &&
                      (data?.discountCoupon ? (
                        <S4.StyledSmallButton as="button" type="button" onClick={() => removeCouponPrice(data.billingDetailId)}>
                          {formatCurrency(data.discountCoupon)}
                          {t(IK.krw)}
                          <i className="ri-close-line"></i>
                        </S4.StyledSmallButton>
                      ) : (
                        <S4.StyledSmallButton as="button" type="button" onClick={() => onModalOpen(data.billingDetailId)}>
                          {`${t(IK.select)} ${t(IK.coupon)} `}
                          <i className="ri-checkbox-multiple-blank-line"></i>
                        </S4.StyledSmallButton>
                      ))}
                  </td>
                  <td>
                    <S3.TextDiv>
                      {formatCurrency(data.paymentPrice)}
                      {t(IK.krw)}
                    </S3.TextDiv>
                  </td>
                  <td>
                    <S3.TextDiv $textpreline>{data.memo}</S3.TextDiv>
                  </td>
                </tr>
              ))}
            </tbody>
          </S.BoardTable>

          {billChecks > 0 && (
            <S3.ContentBox $large>
              <Formik
                initialValues={{
                  paymentMethod: "",
                }}
                validationSchema={validationSchema}
                onSubmit={submit}
                validateOnMount={true}
                innerRef={formikRef}
              >
                {({ setFieldValue, values }) => (
                  <Form onKeyDown={enterKeyDown}>
                    <S5.TitleV3>
                      <h2>
                        {t(IK.bill_selected_item_1)}{" "}
                        <S3.TextDiv as="strong" $textColorBlue400>
                          {checkedBills}
                        </S3.TextDiv>
                        {t(IK.bill_selected_item_2)}
                      </h2>
                    </S5.TitleV3>
                    <S2.TableType2>
                      <colgroup>
                        <col width="200px" />
                        <col width="*" />
                        <col width="200px" />
                        <col width="*" />
                      </colgroup>
                      <tbody>
                        <tr>
                          <th scope="row">{t(IK.pay_methods)}</th>
                          <td colSpan={3}>
                            <div className="chk_area">
                              <div className="radio">
                                <label className="label_radio">
                                  <Field type="radio" name="paymentMethod" className="input_radio" value="1" disabled={totals.paymentPrice === 0} />
                                  <span>{t(IK.card)}</span>
                                </label>
                              </div>
                              <div className="radio">
                                <label className="label_radio">
                                  <Field type="radio" name="paymentMethod" className="input_radio" value="2" disabled={totals.paymentPrice === 0} />
                                  <span>{t(IK.trans)}</span>
                                </label>
                              </div>
                              <div className="radio">
                                <label className="label_radio">
                                  <Field type="radio" name="paymentMethod" className="input_radio" value="3" disabled={totals.paymentPrice === 0} />
                                  <span>{t(IK.vbank)}</span>
                                </label>
                              </div>
                              {(values.paymentMethod === "2" || values.paymentMethod === "3") && (
                                <div className="radio">
                                  <label className="label_radio">
                                    <Field
                                      type="radio"
                                      name="escrow"
                                      className="input_checkbox"
                                      checked={isEscrow}
                                      onClick={() => {
                                        setIsEscrow((x) => !x);
                                      }}
                                    />
                                    <span>{`${t(IK.ascrow_billing)}`}</span>
                                  </label>
                                </div>
                              )}
                            </div>
                            <FieldMessageErrorClick name="paymentMethod" />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t(IK.total_price)}</th>
                          <td>
                            {formatCurrency(totals.salePrice)}
                            {t(IK.krw)}
                          </td>
                          <th scope="row">{t(IK.total_discount)}</th>
                          <td>
                            <S3.TextDiv $textColorRed600>
                              -{formatCurrency(totals.discountPrice)}
                              {t(IK.krw)}
                            </S3.TextDiv>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t(IK.total_coupon)}</th>
                          <td colSpan={3}>
                            <S3.TextDiv $textColorRed600>
                              -{formatCurrency(totals.discountCoupon)}
                              {t(IK.krw)}
                            </S3.TextDiv>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">{t(IK.final_price)}</th>
                          <td colSpan={3}>
                            <S3.TextDiv $textColorBlue400 $textSize24 $textBold800>
                              {formatCurrency(totals.paymentPrice)}
                              {t(IK.krw)}
                            </S3.TextDiv>
                          </td>
                        </tr>
                      </tbody>
                    </S2.TableType2>

                    <CardTail line>
                      <S4.ButtonLtBox>
                        <S4.StyledButton to={`/account/billing-list?page=${state?.page || 1}`}>{t(IK.list)}</S4.StyledButton>
                        {totals.salePrice !== 0 && (
                          <S4.StyledButton as="button" $primary type="submit">
                            {t(IK.pay)}
                          </S4.StyledButton>
                        )}
                      </S4.ButtonLtBox>
                    </CardTail>
                  </Form>
                )}
              </Formik>
            </S3.ContentBox>
          )}
        </CardInnerLayout>
      )}
      <CouponModal
        couponModal={couponModal}
        setCouponModal={setCouponModal}
        couponList={couponList}
        setCouponList={setCouponList}
        couponId={couponId}
        setBillsInfo={setBillsInfo}
        billsInfo={billsInfo}
        isCouponLoading={isCouponLoading}
        setApplyCoupon={setApplyCoupon}
      />
      <PaymentDetailModal paymentDetailModal={paymentDetailModal} closePaymentDetailModal={closePaymentDetailModal} />
    </>
  );
}

export default BillingPayment;
