import { useTranslation } from "react-i18next";
import * as S from "../../../components/Modal/style/Modal.style";
import * as S3 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S6 from "../../../components/element/Form/style/Form.style";
import * as S2 from "../../../components/element/SelectionControls/style/SelectionControls.style";
import * as S4 from "../../../components/element/Title/style/Title.style";
import * as S5 from "../../../styles/Common";
import { IK } from "../../../utils/i18n/keyStore";
import Modal from "../../Modal/Modal";
import { FieldMessageError } from "../FieldMessage/FieldMessage";

export const ScanModelModal = ({ modalOpen, closeModal, handlePlasterModelSubmit, setDeliveryStaus, deliveryStatus, setErrorDelivery, errorDelivery }) => {
  const { t } = useTranslation(["translation"]);

  const handleChange = (event) => {
    setDeliveryStaus(event.target.value);
    setErrorDelivery(false);
  };

  return (
    <Modal open={modalOpen} close={closeModal} header={t(IK.send_model)}>
      <S.ModalBoxList>
        <S.ModalBoxItem>
          <S4.TitleIco>
            <i className="ri-map-pin-fill"></i>
            <h2>{t(IK.shipping_address)}</h2>
          </S4.TitleIco>
          <S.ModalBoxBullet>
            <li>{t(IK.address_info)}</li>
          </S.ModalBoxBullet>
        </S.ModalBoxItem>

        <S.ModalBoxItem>
          <S4.TitleIco>
            <i className="ri-error-warning-fill"></i>
            <h2>{t(IK.send_model_message_1)}</h2>
          </S4.TitleIco>
          <S.ModalBoxBullet>
            <li>{t(IK.send_model_message_2)}</li>
            <li>{t(IK.send_model_message_3)}</li>
            <li>{t(IK.send_model_message_4)}</li>
            <li>
              {t(IK.send_model_message_5)}
              <S5.TextDiv as="p" $textColorRed600>
                {t(IK.send_model_message_6)}
              </S5.TextDiv>
              <S5.TextDiv as="p" $textColorRed600>
                {t(IK.send_model_message_7)}
              </S5.TextDiv>
            </li>
          </S.ModalBoxBullet>
        </S.ModalBoxItem>

        {setDeliveryStaus && (
          <S.ModalBoxItem>
            <S4.TitleIco>
              <i className="ri-truck-fill"></i>
              <h2>{t(IK.address_delivery_check)}</h2>
            </S4.TitleIco>

            <S6.FormControlsColumn>
              <div>
                <S2.SelectionItemList>
                  <S2.SelectionItem>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" name="isDelivery" value="true" onChange={handleChange} />
                      <S2.SelectionItemSpan>{t(IK.address_delivery_check_title1)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                  <S2.SelectionItem>
                    <S2.SelectionItemLabel>
                      <S2.SelectionItemInput $radio type="radio" name="isDelivery" value="false" onChange={handleChange} />
                      <S2.SelectionItemSpan>{t(IK.address_delivery_check_title2)}</S2.SelectionItemSpan>
                    </S2.SelectionItemLabel>
                  </S2.SelectionItem>
                </S2.SelectionItemList>
                {errorDelivery && <FieldMessageError text={t(IK.address_delivery_check_message)} />}
              </div>

              {deliveryStatus === "true" && (
                <S.ModalBoxCard>
                  <S.ModalBoxBullet>
                    <S5.TextDiv as="li" $textColorRed600 $textBold600>
                      {t(IK.address_delivery_check_title1_txt4)}
                    </S5.TextDiv>
                    <li>
                      {t(IK.address_delivery_check_title1_txt1)}
                      <br />
                      {t(IK.address_delivery_check_title1_txt2)}
                    </li>
                    <li>{t(IK.address_delivery_check_title1_txt3)}</li>
                  </S.ModalBoxBullet>
                </S.ModalBoxCard>
              )}

              {deliveryStatus === "false" && (
                <S.ModalBoxCard>
                  <S.ModalBoxBullet>
                    <S5.TextDiv as="li" $textBold600>
                      {t(IK.address_delivery_check_title2_txt1)}
                    </S5.TextDiv>
                  </S.ModalBoxBullet>
                </S.ModalBoxCard>
              )}
            </S6.FormControlsColumn>
          </S.ModalBoxItem>
        )}
      </S.ModalBoxList>

      <S.ModalBoxBtn>
        <S3.ButtonFlex>
          <S3.StyledButton as="button" type="button" onClick={closeModal}>
            {t(IK.cancel)}
          </S3.StyledButton>
          <S3.StyledButton as="button" type="button" $primary onClick={handlePlasterModelSubmit}>
            {t(IK.next)}
          </S3.StyledButton>
        </S3.ButtonFlex>
      </S.ModalBoxBtn>
    </Modal>
  );
};
