import { IK } from "./i18n/keyStore";

/**임상 상태 표기하기 */
export const clinicConditionObject = {
  crowding: IK.crowding,
  spacing: IK.Interdental_space,
  class1: "Class I",
  class2: "Class II div 1",
  class3: "Class II div 2",
  class4: "Class III",
  asymmetric: IK.asymmetry,
  openbiteVerticality: IK.open_bite_vertical,
  openbiteOverjet: IK.open_bite_horizontal,
  deepBite: IK.deepbite,
  abteriorCrossbite: IK.anterior_crossbite,
  posteriorCrossbite: IK.posterior_crossbite,
  narrowArch: IK.narrow_dental_arch,
  flaredTeeth: IK.prolapse_of_anterior_teeth,
  unevenSmile: IK.smile_line_needs_improvement,
  occlusalPlaneRight: IK.Inclined_occlusal_plane_right,
  occlusalPlaneLeft: IK.Inclined_occlusal_plane_left,
  misshapenTeeth: IK.abnormally_shaped_teeth,
  missing: IK.missing,
  other: IK.other,
};

/**임상증례 제출용 배열 변환 */
export const clinicConditionArray = (data = [], t, etc) => {
  const conditions = data.filter((item) => item !== "otherText").map((item) => t(clinicConditionObject[item]));
  !!etc && conditions.push(`${t(IK.other)}(${etc})`);

  return conditions.join(", ");
};

/**임상상태가 배열인 경우 */
export function getConditionItem(data = [], t) {
  return data
    .filter((item) => item.itemKey !== "other")
    .map((item) => (item.itemKey === "otherText" ? `${item.itemValue}(${t(clinicConditionObject["other"])})` : t(clinicConditionObject[item.itemKey])))
    .join(", ");
}

/**임상상태가 문자열인 경우 */
export function getConditionSting(data, t) {
  return data
    .split(",")
    .map((item) => (clinicConditionObject[item] ? t(clinicConditionObject[item]) : `${item.split(":")[1]}(${t(IK.other)})`))
    .join(", ");
}
