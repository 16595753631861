import styled from "styled-components";

export const SidebarArea = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 260px;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.gray300};
  border-right: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const SidebarHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 0 15px;
`;

export const SidebarProfile = styled.div`
  padding: 20px 25px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  text-align: center;
  button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 85px;
    height: 85px;
    padding: 7px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    border-radius: 18px;
    background-color: ${(props) => props.theme.colors.white};
    span {
      position: absolute;
      top: -9px;
      right: -10px;
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  h3 {
    margin-top: 10px;
    font-size: 20px;
    span {
      display: block;
      font-size: 15px;
    }
  }
  p {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 16px;
    border-radius: 20px;
    border: 1px solid ${(props) => props.theme.colors.gray200};
    background-color: ${(props) => props.theme.colors.white};
    font-size: 14px;
    font-weight: 600;
  }
`;

export const SidebarNav = styled.nav`
  flex: 1 0;
  overflow: auto;
  padding: 15px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 0;
  }
  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.gray400};
  }
  &::-webkit-scrollbar-corner {
    background-color: #1a202e;
  }
`;

export const MenuIco = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  font-size: 17px;
  border-radius: 8px;
  transition: all 0.3s;
`;

export const MenuTxt = styled.span`
  font-size: 15px;
  transition: all 0.3s;
`;

export const MenuArrow = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 22px;
  margin-left: auto;
  font-size: 24px;
  transition: all 0.3s;
`;

export const SidebarNavDepth2 = styled.ul`
  display: none;
  margin-top: 8px;
  li a {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    ${MenuIco} {
      opacity: 0;
      font-size: 24px;
    }
    &.active ${MenuIco} {
      opacity: 1;
    }
  }
`;

export const SidebarNavDepth1 = styled.ul`
  > li {
    margin: 7px 0;
    &:first-child {
      margin-top: 0;
  }
  > a {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px 10px;
    border-radius: 6px;
    &.active, &:hover {
      background: ${(props) => props.theme.colors.blue400};
      span {
        color: ${(props) => props.theme.colors.white};
      }
    }
    &.active ${MenuArrow} {
      transform: rotate(90deg);
    }
    &.active + ${SidebarNavDepth2} {
      display: block;
    }
  }
`;

export const SidebarCont = styled.div`
  padding: 20px 25px;
  border-top: 1px solid ${(props) => props.theme.colors.gray200};
`;

export const SidebarMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 15px;
  li a,
  li button {
    display: flex;
    align-items: center;
  }
`;
