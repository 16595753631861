import { useTranslation } from "react-i18next";
import { downloadFile } from "../../../apis/boardApi";
import * as S4 from "../../../components/element/Button/style/ButtonLayout.style";
import * as S2 from "../../../components/element/Drop/style/Drop.style";
import { Preview } from "../../../pages/Patients/Summary/Sections/Preview";
import * as S3 from "../../../styles/Common";
import { additionalFormConfig } from "../../../utils/additionalStudyUtils";
import { IK } from "../../../utils/i18n/keyStore";
import { plasterModelSend } from "../../../utils/studyUtils";
import TeethSummaryBox from "../TeethTable/TeethSummaryBox";
import * as S from "./style/Summary.style";
import { SummaryTableLi } from "./summaryTableLi";

export const AdditionalSummaryTable = ({ additionalInfo, handleClickForUpdate, currentStepList }) => {
  const { t } = useTranslation(["translation"]);

  function checkLabelExists(arr, targetLabel) {
    return arr.some((item) => item.label === targetLabel);
  }

  return (
    <S.SummaryList>
      {/* 의사명 */}
      <SummaryTableLi title={t(IK.doctor_name)}>
        {additionalInfo.doctor && (
          <p>
            {additionalInfo.doctor.name} {additionalInfo.doctor?.koreaName && `(${additionalInfo.doctor.koreaName})`}
          </p>
        )}
      </SummaryTableLi>

      {/* 인쇄시 환자명, 치료옵션 보이도록 */}
      <SummaryTableLi printshow title={t(IK.patient_name)}>
        {additionalInfo?.patient && (
          <p>{`${additionalInfo.patient?.firstName} ${additionalInfo.patient?.lastName}${additionalInfo.patient?.korName ? ` (${additionalInfo.patient?.korName})` : ""}`}</p>
        )}
      </SummaryTableLi>
      <SummaryTableLi printshow title={t(IK.treatment_option)}>
        {additionalInfo.study && <p>{`${t(IK[additionalInfo.study?.packages])}`}</p>}
      </SummaryTableLi>

      {/* 배송지 */}
      <SummaryTableLi title={t(IK.shipping_address)}>{additionalInfo.patient && <p>{additionalInfo.patient.shipAddressName}</p>}</SummaryTableLi>

      {/* 청구지 */}
      <SummaryTableLi title={t(IK.billing_address)}>{additionalInfo.patient && <p>{additionalInfo.patient.billAddressName}</p>}</SummaryTableLi>

      {/* 구외 사진 */}
      {checkLabelExists(currentStepList, "Extraoral") && (
        <SummaryTableLi title={t(IK.extraoral)} handleClickForUpdate={handleClickForUpdate} dataId="Extraoral">
          <S2.DropListWrap>
            <S2.DropList className="extraoral_front">
              <S2.DropItem>{additionalInfo.extraoral_front && <Preview path={additionalInfo.extraoral_front?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="front_rest">
              <S2.DropItem>{additionalInfo.front_rest && <Preview path={additionalInfo.front_rest?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="front_smile">
              <S2.DropItem>{additionalInfo.front_smile && <Preview path={additionalInfo.front_smile?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="side45">
              <S2.DropItem>{additionalInfo.side45 && <Preview path={additionalInfo.side45?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="side90">
              <S2.DropItem>{additionalInfo.side90 && <Preview path={additionalInfo.side90?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="side90_smile">
              <S2.DropItem>{additionalInfo.side90_smile && <Preview path={additionalInfo.side90_smile?.distFileName} />}</S2.DropItem>
            </S2.DropList>
          </S2.DropListWrap>
        </SummaryTableLi>
      )}

      {/* 구내 사진 */}
      {checkLabelExists(currentStepList, "Intraoral") && (
        <SummaryTableLi title={t(IK.intraoral)} handleClickForUpdate={handleClickForUpdate} dataId="Intraoral">
          <S2.DropListWrap>
            <S2.DropList className="occlusal_upper">
              <S2.DropItem>{additionalInfo.occlusal_upper && <Preview path={additionalInfo.occlusal_upper?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="intraoral_front">
              <S2.DropItem>{additionalInfo.intraoral_front && <Preview path={additionalInfo.intraoral_front?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="occlusal_lower">
              <S2.DropItem>{additionalInfo.occlusal_lower && <Preview path={additionalInfo.occlusal_lower?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="buccal_right">
              <S2.DropItem>{additionalInfo.buccal_right && <Preview path={additionalInfo.buccal_right?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="overjet">
              <S2.DropItem>{additionalInfo.overjet && <Preview path={additionalInfo.overjet?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="buccal_left">
              <S2.DropItem>{additionalInfo.buccal_left && <Preview path={additionalInfo.buccal_left?.distFileName} />}</S2.DropItem>
            </S2.DropList>
          </S2.DropListWrap>
        </SummaryTableLi>
      )}

      {/* 방사선 사진 */}
      {checkLabelExists(currentStepList, "Radiation") && (
        <SummaryTableLi title={t(IK.radiograph)} handleClickForUpdate={handleClickForUpdate} dataId="Radiation">
          <S2.DropListWrap $radiation>
            <S2.DropList className="panorama">
              <S2.DropItem $radiation>{additionalInfo.panorama && <Preview path={additionalInfo.panorama?.distFileName} />}</S2.DropItem>
            </S2.DropList>
            <S2.DropList className="cephalo">
              <S2.DropItem $radiation>{additionalInfo.cephalo && <Preview path={additionalInfo.cephalo?.distFileName} />}</S2.DropItem>
            </S2.DropList>
          </S2.DropListWrap>
        </SummaryTableLi>
      )}

      {/* 스캔파일 */}
      {checkLabelExists(currentStepList, "Scan") && (
        <SummaryTableLi title={t(IK.scan_file_registration)} handleClickForUpdate={handleClickForUpdate} dataId="Scan">
          {additionalInfo.scan?.length > 0 ? (
            <S4.ButtonFlex>
              {additionalInfo.scan?.map((item, index) => {
                return (
                  <S4.StyledSmallButton as="button" key={index} onClick={() => downloadFile(item.fileId, item.orgFileName)}>
                    {item.orgFileName}
                  </S4.StyledSmallButton>
                );
              })}
            </S4.ButtonFlex>
          ) : (
            <p>{plasterModelSend(t, additionalInfo?.AIPlasterModel, additionalInfo?.AI40)}</p>
          )}
        </SummaryTableLi>
      )}

      {/* 현재 환자가 착용하고 있는 장치 번호는? */}
      {checkLabelExists(currentStepList, "CurrentDeviceNumber") && (
        <SummaryTableLi title={t(IK.current_device_number_title)} handleClickForUpdate={handleClickForUpdate} dataId="CurrentDeviceNumber">
          <div className="frm_column">
            {additionalInfo.AI02_01 && (
              <p>
                {t(IK.maxillary_aligner_number)} : {additionalInfo.AI02_01}
              </p>
            )}
            {additionalInfo.AI02_02 && (
              <p>
                {t(IK.mandibular_aligner_number)} : {additionalInfo.AI02_02}
              </p>
            )}
          </div>
        </SummaryTableLi>
      )}

      {/* 어태치먼트 유지 및 제거 여부 */}
      {checkLabelExists(currentStepList, "Attachment") && (
        <SummaryTableLi title={t(IK.attachment_select)} handleClickForUpdate={handleClickForUpdate} dataId="Attachment">
          <p>{t(IK[`additional_attachment_item${additionalInfo.AI04}`])}</p>
        </SummaryTableLi>
      )}

      {/* 어태치먼트 AP*/}
      {checkLabelExists(currentStepList, "ApAttachment") && (
        <SummaryTableLi title={t(IK.ap_attachment)} handleClickForUpdate={handleClickForUpdate} dataId="ApAttachment">
          {additionalInfo.AI07 === "1" && <p>{t(IK.ap_attachment_text_1)}</p>}
          {additionalInfo.AI07 === "2" && <p>{t(IK.ap_attachment_text_2)}</p>}
          {additionalInfo.AI07 === "3" && <p>{t(IK.ap_attachment_text_3)}</p>}
        </SummaryTableLi>
      )}

      {/* 이동에 제한이 있는 치아 */}
      {checkLabelExists(currentStepList, "TeethLimitedMobility") && (
        <SummaryTableLi title={t(IK.teeth_limited_mobility)} handleClickForUpdate={handleClickForUpdate} dataId="TeethLimitedMobility">
          {additionalInfo.AI05 === "1" && <p>{t(IK.teeth_limited_mobility_item1)}</p>}
          {additionalInfo.AI05 === "2" && <TeethSummaryBox label="permanent" studyInfo={additionalInfo} config={additionalFormConfig.teeth_limited_mobility_item2_teeth} code="AI05" />}
        </SummaryTableLi>
      )}

      {/* 특별지시사항 */}
      <SummaryTableLi title={t(IK.special_instructions)} handleClickForUpdate={handleClickForUpdate} dataId="Instructions">
        <S3.TextDiv $textpreline>{additionalInfo.AI06}</S3.TextDiv>
      </SummaryTableLi>
    </S.SummaryList>
  );
};
