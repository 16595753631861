import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

/**사용자 정보 */
export const userInfo = atom({
  key: "userInfo",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

/**사용자 권한 */
export const userRole = atom({
  key: "userRole",
  default: "",
  effects_UNSTABLE: [persistAtom],
});

/**선호치료계획 적용 유무 */
export const userIsPreferedPlan = atom({
  key: "userIsPreferedPlan",
  default: 0,
});
