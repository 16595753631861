import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { studySubmit } from "../../../../../apis/studyApi";
import {
  CardTitle,
  CardTail,
} from "../../../../../components/Layout/CardLayout/CardLayout";
import { FormConfirm } from "../../../../../components/element/Form/FormLayout";
import { IK } from "../../../../../utils/i18n/keyStore";
import * as S from "../../../../../components/element/Button/style/ButtonLayout.style";

function Confirm({ studyInfo, patientId, prev }) {
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();

  /**처방전 제출 */
  const hanldeSubmit = function () {
    studySubmit(patientId, studyInfo.studyId)
      .then((res) => {
        navigate(`/patient/${patientId}/profile`);
      })
      .catch((err) => {
        Notiflix.Notify.failure(t(IK.again_message));
      });
  };

  return (
    <>
      <CardTitle
        title={
          <>
            {studyInfo.patientName}
            <span>({`${t(IK[studyInfo.packages])}`})</span>
          </>
        }
      />

      <FormConfirm title={t(IK.checklist_message1)}>
        <p>
          {t(IK.checklist_message2)}
          <br />
          {t(IK.checklist_message3)}
        </p>
      </FormConfirm>

      <CardTail line>
        <S.ButtonLtBox>
          <S.StyledButton as="button" type="button" onClick={prev}>
            {t(IK.prev)}
          </S.StyledButton>
          <S.StyledButton
            as="button"
            $primary
            type="submit"
            onClick={() => {
              hanldeSubmit();
            }}
          >
            {t(IK.submitted)}
          </S.StyledButton>
        </S.ButtonLtBox>
      </CardTail>
    </>
  );
}

export default Confirm;
