import { cloneElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useDoctorAuth } from "../../../Hooks/useDoctorAuth";
import useLocalStorageState from "../../../Hooks/useLocalStorageState";
import { useGetAdditionalSummary } from "../../../apis/additionalStudyApi";
import { useAuthAdminDoctor } from "../../../apis/doctorApi";
import { meditScan } from "../../../atoms/meditAtom";
import { CardInnerLayout } from "../../../components/Layout/CardLayout/CardLayout";
import * as S from "../../../components/Layout/CardLayout/style/CardLayout.style";
import LoadingIndicator from "../../../components/loading/LoadingIndicator/LoadingIndicator";
import { additionalSteps } from "../../../utils/additionalStudyUtils";
import { stepTypes } from "../../../utils/studyUtils";
import Filter from "../Study/Sections/Filter/Filter";
import Stpes from "./Section/Stpes/Stpes";

function AdditionalStudy() {
  const { additionalId, patientId, studyId } = useParams();

  /**처방전 작성권한 조회 */
  useAuthAdminDoctor(patientId);

  /**담당환자 여부 조회 */
  const access = useDoctorAuth(patientId);

  /**추가 처방전 정보값 저장 */
  const [additionalInfo, setAdditionalInfo] = useState({});

  /**새로고침시 페이지, 데이터 날라가지 않도록 */
  const [currentStepType, setCurrentStepType] = useLocalStorageState("additionalStepTypes" + additionalId, "NOT_EDIT");
  const [currentStepIndex, setCurrentStepIndex] = useLocalStorageState("additionalStepIndex" + additionalId, 0);
  const [reloading, setReloading] = useState(true);

  /**추가 처방전 정보 가져오기 */
  const { data: additionalData, isLoading } = useGetAdditionalSummary(additionalId, patientId, studyId);
  const additionalStudyData = additionalData?.data;

  /**요약 수정인지 아닌지 판단 */
  const [isFinish, setIsFinish] = useState(false);

  const setMeditFiles = useSetRecoilState(meditScan);
  const navigate = useNavigate();

  // 현재 단계에 대한 정보 가져오기
  const currentStepList = additionalSteps[currentStepType];
  const currentStep = currentStepList[currentStepIndex];

  useEffect(() => {
    if (additionalData && !isLoading) {
      let currentStepList = [];
      if (additionalStudyData.additional?.process === "none") {
        setCurrentStepType(stepTypes.NOT_EDIT);
        currentStepList = [...additionalSteps[stepTypes.NOT_EDIT]];
      } else {
        setCurrentStepType(additionalStudyData.study.packages);
        currentStepList = [...additionalSteps[additionalStudyData.study.packages]];
      }

      let index = currentStepList.findIndex((step) => {
        if (step.validateCode) {
          if (step.validateCode === "process") {
            return !additionalStudyData.AI02_01;
          } else {
            const codes = step.validateCode.split(",");
            return codes.every((code) => !additionalStudyData[code]);
          }
        }
        return false;
      });
      if (index === -1) {
        index = currentStepList.length - 1;
      }
      if (index !== -1 && currentStepIndex === 0) {
        setCurrentStepIndex(index);
      }

      const { firstName, lastName, korName } = additionalStudyData.patient;
      const { studyId, age, packages } = additionalStudyData.study;

      setAdditionalInfo({
        ...additionalStudyData,
        patientName: `${firstName} ${lastName}${korName ? ` (${korName})` : ""}`,
        studyId,
        age: age,
        packages,
        process: additionalStudyData.additional.process.toUpperCase(),
      });
      if (additionalStudyData.detail === "12") {
        setIsFinish(true);
      }
      setReloading(false);
    }
    // eslint-disable-next-line
  }, [isLoading, additionalData]);

  /**다음 단계로 이동 */
  function next() {
    if (currentStepIndex < currentStepList.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
    }
  }

  /**이전 단계로 이동 */
  function prev() {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
    } else {
      navigate(`/patient/${patientId}/profile`);
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0 });
    /**미등록 이탈시 데이터 지우기 */
    return () => {
      setMeditFiles([]);
    };
  }, [currentStepIndex, setMeditFiles]);

  if (!access) {
    return null;
    //권한 없을시 컴포넌트 렌더링 안함
  }

  return (
    <>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Stpes currentStepList={currentStepList} currentStepIndex={currentStepIndex} />

          <S.CardFlex>
            <S.CardColWd $full>
              <CardInnerLayout>
                {!reloading &&
                  cloneElement(currentStep.component, {
                    next,
                    prev,
                    currentStepType,
                    setCurrentStepType,
                    setCurrentStepIndex,
                    additionalInfo,
                    setAdditionalInfo,
                    stepLabel: currentStep?.label,
                    currentStepList,
                    currentStepIndex,
                    setIsFinish,
                    isFinish,
                    additionalId,
                    patientId,
                    studyId,
                  })}
              </CardInnerLayout>
            </S.CardColWd>
            {currentStepType !== "NOT_EDIT" && <Filter studyInfo={additionalInfo} currentStepIndex={currentStepIndex} currentStepList={currentStepList} />}
          </S.CardFlex>
        </>
      )}
    </>
  );
}
export default AdditionalStudy;
