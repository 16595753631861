import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";
import { useRememberPage } from "../../../Hooks/useRememberPage";
import { useGetBoardList } from "../../../apis/boardApi";
import {
  CardInnerLayout,
  CardTail,
} from "../../../components/Layout/CardLayout/CardLayout";
import CommonImage from "../../../components/common/CommonImage";
import { BoardSkeleton } from "../../../components/element/BoardList/BoardSkeleton";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";

function LibraryList() {
  /**페이지 기억하기 */
  const [currentPage, setCurrentPage] = useRememberPage();

  /**페이지 변경 */
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  /**자료실 게시판 조회 */
  const { data, isLoading } = useGetBoardList({
    sort: "MARKETING",
    size: 10,
    page: currentPage - 1,
  });
  const libraryList = data?.data;

  return (
    <CardInnerLayout>
      {isLoading ? (
        <BoardSkeleton />
      ) : (
        <>
          {libraryList.content.length > 0 ? (
            <S.BoardCardList>
              {libraryList.content.map((item, index) => (
                <li key={index}>
                  <Link
                    to={`/education/library-list/${item.boardId}`}
                    state={{ page: currentPage }}
                  >
                    <dl>
                      <dt>
                        <CommonImage filePath={item?.distFileName} />
                      </dt>
                      <dd>
                        <h3>{item.title}</h3>
                        <span>
                          {dayjs(item.createdDate).format("YYYY.MM.DD")}
                        </span>
                      </dd>
                    </dl>
                  </Link>
                </li>
              ))}
            </S.BoardCardList>
          ) : (
            <EmptyCard />
          )}
          <CardTail line>
            <Paging
              totalCount={libraryList.totalElements}
              currentPage={currentPage}
              size={10}
              handleChangePage={handleChangePage}
            />
          </CardTail>
        </>
      )}
    </CardInnerLayout>
  );
}

export default LibraryList;
