import axios from "axios";
import Notiflix from "notiflix";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { IK } from "../utils/i18n/keyStore";

//세라핀 교정 더 잘하기 = etc, 교육 자료 = references, 자료실 = marketing, 정책, 양식 및 사용설명서 = support
/** 게시판 리스트 조회*/
export const useGetBoardList = (params) => {
  const { t } = useTranslation();
  return useQuery(
    ["BoardList", params],
    async () => {
      const { data } = await axios.get(`/api/board/list`, {
        params,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/** 게시판 상세 정보 */
export const useGetBoardInfo = (boardId) => {
  const { t } = useTranslation();
  return useQuery(
    ["BoardInfo", boardId],
    async () => {
      const { data } = await axios.get(`/api/board/${boardId}`);
      return data;
    },
    {
      enabled: !!boardId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**게시판 파일 다운로드 */
export const downloadFile = async (fileId, fileName, t) => {
  try {
    Notiflix.Loading.standard("");
    const response = await axios.get(`/api/file/${fileId}`, {
      responseType: "blob",
    });

    const fileBlob = new Blob([response.data], { type: "application/octet-stream" });
    const fileURL = URL.createObjectURL(fileBlob);

    const anchor = document.createElement("a");
    anchor.href = fileURL;
    anchor.download = fileName;
    anchor.click();

    URL.revokeObjectURL(fileURL);
    anchor.remove();
  } catch (error) {
    Notiflix.Notify.failure(t(IK.again_message));
  } finally {
    Notiflix.Loading.remove();
  }
};

/**이벤트 게시판 리스트 조회 */
export const useGetEventList = (searchCondition) => {
  const { t } = useTranslation();
  return useQuery(
    ["EventList", searchCondition],
    async () => {
      const { data } = await axios.get("/api/event-board/list", {
        params: searchCondition,
      });
      return data;
    },
    {
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**이벤트게시판 상세조회 */
export const useGetEventInfo = (eventBoardId) => {
  const { t } = useTranslation();
  return useQuery(
    ["EventList", eventBoardId],
    async () => {
      const { data } = await axios.get(`/api/event-board/${eventBoardId}?code=VIP`);
      return data;
    },
    {
      enabled: !!eventBoardId,
      onError: (error) => {
        Notiflix.Report.warning(t(IK.server_error2), t(IK.server_error1), t(IK.confirm), () => {});
      },
    }
  );
};

/**이벤트 배너 리스트 조회 */
export const useGetBannerList = () => {
  return useQuery(["BannerList"], async () => {
    const { data } = await axios.get(`/api/banner/list`);
    return data;
  });
};

/**샘플세라뷰 조회 */
export const getSampleSeraview = async (studyId, studyDesignId) => {
  return await axios.get(`/api/board/support/seraview/open`, {
    params: {
      studyId,
      studyDesignId,
    },
  });
};
