import axios from "axios";
import Notiflix from "notiflix";

/** 면허 중복 확인  */
export const checkLicenseNumber = async (license) => {
  const response = await axios.post(`/api/account/check-license`, license);
  return response;
};

/** 아이디 중복 확인  */
export const checkLoginId = async (id) => {
  const response = await axios.post(`/api/account/check-id`, id);
  return response;
};

/** 이메일 중복 확인  */
export const checkEmail = async (email) => {
  const response = await axios.post(`/api/account/check-email`, email);
  return response;
};

/** 회원가입  */
export const signUp = async (data) => {
  Notiflix.Loading.standard("");
  const formData = new FormData();
  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  Notiflix.Loading.remove();
  const response = await axios.post(`/api/account/join`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

/** referesh 토큰 지우기  */
export const deleteRefreshToken = async () => {
  const response = await axios.delete(`/api/auth/refresh-token`);
  return response;
};

/**로그인 화면에서 referesh 토큰 유효성 체크  */
export const checkToken = async () => {
  const response = await axios.get(`/api/auth/is-login`);
  return response;
};
