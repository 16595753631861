import axios from "axios";
import { useMutation, useQuery } from "react-query";

/** 영상 시청 리스트 조회 */
export const useGetCertifiedVideoList = () => {
  return useQuery(["VideoList"], async () => {
    const { data } = await axios.get(`/api/doctor/education/video`);
    return data;
  });
};

/** 영상 시청 내역 업데이트 */
export const useUpdateCertifiedVideo = () => {
  return useMutation(async (value) => {
    await axios.post(`/api/doctor/education/video?doctorVideoCode=${value}`);
  });
};
