import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../../../Hooks/useSearch";
import { useGetBoardList } from "../../../apis/boardApi";
import * as S2 from "../../../components/Layout/CardLayout/style/CardLayout.style";
import CommonImage from "../../../components/common/CommonImage";
import { BoardSkeleton } from "../../../components/element/BoardList/BoardSkeleton";
import * as S from "../../../components/element/BoardList/style/BoardList.style";
import BoardSearchBar from "../../../components/element/BoardSearch/BoardSearchBar";
import { EmptyCard } from "../../../components/element/EmptyCard/EmptyCard";
import Paging from "../../../components/element/Paging/Paging";
import { changeBoardParam, urlParamToObject } from "../../../utils/searchUtils";
import { CardTail } from "../../../components/Layout/CardLayout/CardLayout";

function CorrectionImageList() {
  /**검색 조건 설정 */
  const [searchCondition, setSearchCondition] = useState(
    window.location.search
      ? urlParamToObject()
      : {
          page: 1,
          sort: "ETC",
        }
  );

  /**검색 조건 url에 추가 */
  useSearch(searchCondition);

  /**페이지 변경 */
  const handleChangePage = (e) => {
    setSearchCondition((prev) => ({ ...prev, page: e }));
  };

  /**더 잘하기 게시판 조회 */
  const { data, isLoading } = useGetBoardList(
    changeBoardParam(searchCondition)
  );
  const betterList = data?.data;

  return (
    <S2.CardRow>
      <BoardSearchBar
        searchCondition={searchCondition}
        setSearchCondition={setSearchCondition}
        boardSort="ETC"
      />

      <S2.CardCol>
        <S2.CardInner>
          {isLoading ? (
            <BoardSkeleton />
          ) : (
            <>
              {betterList.content.length > 0 ? (
                <S.BoardCardList>
                  {betterList.content.map((item, index) => (
                    <li key={index}>
                      <Link
                        to={`/education/correction-image-list/${item.boardId}`}
                        state={{ page: searchCondition.page }}
                      >
                        <dl>
                          <dt>
                            <CommonImage filePath={item.distFileName} />
                          </dt>
                          <dd>
                            <h3>{item.title}</h3>
                            <span>
                              {dayjs(item.createdDate).format("YYYY.MM.DD")}
                            </span>
                          </dd>
                        </dl>
                      </Link>
                    </li>
                  ))}
                </S.BoardCardList>
              ) : (
                <EmptyCard />
              )}
              <CardTail line>
                <Paging
                  totalCount={betterList.totalElements}
                  currentPage={searchCondition.page}
                  size={10}
                  handleChangePage={handleChangePage}
                />
              </CardTail>
            </>
          )}
        </S2.CardInner>
      </S2.CardCol>
    </S2.CardRow>
  );
}

export default CorrectionImageList;
