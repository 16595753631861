import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { FieldMessageErrorClick } from "../../../../components/element/FieldMessage/FieldMessage";
import { IK } from "../../../../utils/i18n/keyStore";
import { VideoPreview } from "./VideoPreview";

const SeraviewVideo = ({ values, handleVideoCheck }) => {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <VideoPreview youtubeLink="https://www.youtube.com/embed/rMzxSsW3oF4?color=white">
        <div className="checkbox">
          <label className="label_checkbox">
            <Field
              type="checkbox"
              name="certVideo2"
              className="input_checkbox"
              onClick={(e) => {
                const target = e.currentTarget;
                values.certVideo2 ? (target.checked = true) : handleVideoCheck("VIDEO2");
              }}
              checked={values.certVideo2}
            />
            <span>{t(IK.support_link_step2_txt)}</span>
          </label>
        </div>
        <FieldMessageErrorClick name="certVideo2" />
      </VideoPreview>
    </>
  );
};

export default SeraviewVideo;
